@media (max-width: 780px) {
  .container {
    padding: 20px 15px 75px;
  }

  .container-x {
    padding: 0 15px;
  }

  .container-y {
    padding: 20px 0 75px;
  }

  .container-left {
    padding: 20px 15px 75px;
  }

  .container-row {
    padding: 0 15px;
  }

  .gap--40 {
    gap: 35px;
  }

  .gap--30 {
    gap: 25px;
  }

  .league {
    grid-template-columns: repeat(2, 1fr);
  }

  .table-border {
    min-width: 500px;
  }

  .table-border--400 {
    min-width: 400px;
  }

  .table__th {
    font-size: 12px;
    line-height: 16px;
    padding: 5px;
  }

  .table__410 {
    min-width: 180px;
    padding: 5px;
  }

  .table__cell {
    font-size: 16px;
    padding: 10px 5px;
  }

  .table__link {
    gap: 5px;
  }

  .table__number {
    padding: 5px 7px;
    min-width: 35px;
  }

  .buttons__link {
    padding: 8px 10px;
  }

  .chess__wrapper {
    grid-template-columns: 45% auto;
  }

  .match__team {
    padding: 10px;
  }

  .match__img {
    width: 30px;
  }

  .match__number {
    font-size: 16px;
    line-height: 18px;
  }

  .match__wrap {
    grid-template-columns: calc(50% - 35px) 70px calc(50% - 35px);
  }

  .match__end.match__team {
    padding: 10px;
  }

  .filter--tournaments {
    grid-template-columns: repeat(2, 1fr);
  }

  .tournaments__name {
    font-size: 20px;
    line-height: 24px;
  }

  .tournaments__img {
    width: 60px;
  }

  .logo--header .logo__icon {
    width: 95px;
  }

  .header {
    height: 66px;
  }

  .stub__text {
    font-size: 20px;
    line-height: 24px;
  }

  .stub--nav {
    align-items: center;
  }

  .show-780 {
    display: flex;
  }

  .stages__item:not(.stages__item--active) {
    display: none;
  }

  .stages__list {
    background-color: initial;
  }

  .stages__button {
    padding: 5px 35px;
  }

  .footer {
    padding: 20px 15px;
  }

  .logo--footer .logo__icon {
    width: 100px;
  }

  .social__link {
    width: 30px;
    height: 30px;
  }

  .content__text {
    padding: 15px 85px 25px 15px;
  }

  .content__icons {
    right: 15px;
  }

  .content__count {
    width: 65px;
    height: 65px;
    right: 15px;
  }

  .content__date {
    font-size: 16px;
    line-height: 18px;
  }

  .translation__icons {
    padding: 10px;
  }

  .translation__icon {
    width: 29px;
    height: 29px;
  }

  .translation__text {
    font-size: 18px;
    line-height: 22px;
    padding: 15px 20px;
    min-height: 60px;
  }

  .translation {
    grid-template-columns: 1fr;
  }

  .images__icon {
    width: 35px;
  }

  .sidebar {
    margin: -20px 0 0;
  }

  .sidebar__block {
    right: 25px;
  }

  .sidebar__count {
    right: 25px;
  }

  .sidebar__text {
    padding: 15px 60px 20px 15px;
  }

  .album {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  .arrows {
    gap: 35px;
  }

  .author__name {
    font-size: 16px;
  }

  .author__info {
    font-size: 16px;
  }

  .documents {
    margin: 0 -15px;
    padding: 30px 15px;
  }

  .documents--end {
    padding: 30px 10px;
  }

  .documents__icon {
    width: 30px;
  }

  .contact__map {
    height: 430px;
  }

  .contact {
    margin: 0 -15px;
  }

  .sidebar-banner {
    padding: 0 15px 75px;
  }

  .tourney__wrap {
    padding: 15px 20px 15px 15px;
  }

  .contact__block {
    padding: 25px 15px;
  }

  .accordion__block {
    padding: 10px 15px;
    gap: 20px;
  }

  .accordion__wrap {
    padding: 15px;
  }

  .amount {
    line-height: 26px;
  }

  .players__img {
    width: 160px;
  }

  .efficiency {
    width: 45px;
    height: 45px;
    right: 10px;
  }

  .players__name {
    font-size: 22px;
    line-height: 26px;
  }

  .background {
    padding: 50px 25px 55px;
    gap: 35px;
  }

  .background--command {
    padding: 25px 20px;
  }

  .command__icon {
    width: 31px;
  }

  .command {
    gap: 20px;
  }

  .command__wrap {
    gap: 30px;
  }

  .command__title {
    font-size: 22px;
    line-height: 26px;
  }

  .command__number {
    font-size: 20px;
  }

  .player__full {
    font-size: 24px;
    line-height: 30px;
  }

  .player {
    gap: 20px;
  }

  .player__img {
    width: 90px;
  }

  .efficiency__number {
    font-size: 12px;
    line-height: 16px;
  }

  .efficiency--players {
    width: 35px;
    height: 35px;
    right: 0;
  }

  .command__item {
    grid-template-columns: 85px 1fr;
  }

  .modal__background {
    padding: 30px 15px;
  }

  .modal__column {
    margin: -20px 0 0;
  }

  .form__input {
    line-height: 22px;
  }

  .modal__close {
    right: 15px;
    top: -65px;
  }

  .modal__text {
    margin: 0;
  }

  .modal__button {
    font-size: 18px;
  }

  .background-form {
    padding: 35px 15px;
    gap: 30px;
  }

  .compound__item {
    gap: 30px;
  }

  .fields__item {
    grid-template-columns: repeat(1, 1fr);
  }

  .form:nth-child(2n) {
    order: 1;
  }

  .form:nth-last-child(2) {
    order: 1;
  }

  .fields__item--3 .form:nth-child(2n) {
    order: 0;
  }

  .fields__item--3 .form:nth-last-child(2) {
    order: 0;
  }

  .compound {
    gap: 45px;
  }

  .matches__img {
    width: 70px;
  }

  .matches__name {
    font-size: 16px;
    line-height: 20px;
  }

  .matches__number {
    font-size: 46px;
    line-height: 56px;
    min-width: 56px;
  }

  .matches__colon {
    font-size: 30px;
    line-height: 34px;
  }

  .matches__player {
    gap: 15px;
  }

  .matches__wrap {
    grid-template-columns: calc(38% - 10px) calc(24% - 10px) calc(38% - 10px);
    gap: 10px;
  }

  .matches__container {
    padding: 15px 15px 30px;
  }

  .share {
    right: 20px;
  }

  .share__text {
    display: none;
  }

  .game__container {
    padding: 15px 10px 15px 15px;
    gap: 10px;
  }

  .game__container--right {
    padding: 15px 15px 15px 10px;
  }

  .game__img {
    display: none;
  }

  .game__number {
    width: 25px;
    height: 25px;
  }

  .game__scores {
    font-size: 16px;
    line-height: 18px;
  }

  .game__point {
    width: 15px;
    height: 15px;
  }

  .game__victory {
    color: var(--white);
    font-size: 10px;
    line-height: 12px;
    padding: 3px 6px;
    right: -50px;
  }

  .game__container--right .game__victory {
    left: -50px;
  }

  .game__grid {
    grid-template-columns: 1fr 70px 1fr;
  }

  .share__logo {
    width: 12px;
  }

  .personal__text {
    font-size: 18px;
    line-height: 24px;
  }

  .payment__price {
    font-size: 22px;
    line-height: 28px;
  }

  .save--pay {
    max-width: 150px;
    line-height: 26px;
  }

  .payment__title {
    font-size: 22px;
    line-height: 26px;
  }

  .share__socials {
    right: 35px;
  }

  .payment {
    gap: 35px;
  }

  .payment__item {
    gap: 25px;
  }

  .mistake__number {
    font-size: 110px;
    line-height: 125px;
  }

  .mistake {
    gap: 5px;
    padding: 50px 15px;
  }

  .verification {
    font-size: 18px;
    line-height: 24px;
  }

  .table__big {
    font-size: 22px;
    line-height: 26px;
  }

  .widget__link {
    padding: 25px 15px 20px;
    gap: 15px;
  }

  .widget__time,
  .widget__line {
    display: none;
  }

  .widget {
    grid-template-columns: repeat(1, 1fr);
  }

  .widget__name {
    font-size: 16px;
    line-height: 20px;
  }

  .widget__scores {
    font-size: 28px;
    line-height: 34px;
  }

  .widget__teams {
    grid-template-columns: calc((100% - 100px) / 2) 90px calc((100% - 100px) / 2);
  }
}

@media (max-width: 680px) {
  .buttons {
    flex-direction: column;
    width: 100%;
  }

  .buttons:not(.buttons--open) .buttons__item:not(.buttons--open, .buttons__item--active) {
    display: none;
  }

  .buttons__item.buttons__item--active {
    order: -1;
  }

  .buttons__item {
    width: 100%;
    padding: 0;
  }

  .buttons__item--active .buttons__link:after {
    content: url(/src/assets/images/drop-arrow-white.svg);
    position: absolute;
    right: 25px;
    width: 20px;
    height: 20px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttons.buttons--open .buttons__item--active .buttons__link:after {
    transform: rotate(180deg);
    transition: 0.3s;
  }

  .buttons__link {
    font-size: 16px;
    line-height: 20px;
  }

  .footer__container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    align-items: flex-start;
  }

  .footer__nav {
    grid-column: 1/-1;
    grid-row: -1/-2;
    order: 2;
    align-items: center;
  }

  .partners {
    justify-content: center;
  }

  .footer__list {
    align-items: center;
  }

  .document__block {
    align-items: center;
  }

  .logo--footer {
    margin: 0 auto 0 0;
  }

  .logo--footer .logo__icon {
    width: 85px;
  }

  .content {
    grid-template-columns: 1fr;
  }

  .video-iframe iframe {
    height: 381px;
  }

  .filter--pages {
    grid-template-columns: repeat(1, 1fr);
  }

  .pagination--arrows {
    gap: 35px;
  }

  .filter--commands {
    grid-template-columns: repeat(2, 1fr);
  }

  .filter--players {
    grid-template-columns: calc(50% - 40px) calc(50% - 45px) 45px;
  }

  .player {
    grid-template-columns: 1fr;
  }

  .matches__img {
    width: 60px;
  }

  .widget__link {
    padding: 20px 15px;
  }

  .table__points {
    width: auto;
  }
}

@media (max-width: 580px) {
  .filter--league {
    grid-template-columns: repeat(2, 1fr);
  }

  .filter--league .filter__item:nth-child(5) {
    grid-column: 1/3;
  }

  .league {
    grid-template-columns: 1fr;
  }

  .table__127 {
    min-width: 57px;
  }

  .tournament {
    grid-template-columns: 1fr;
  }

  .tournament__item:nth-child(2n) {
    order: 2;
  }

  .author {
    flex-direction: column;
  }

  .sidebar__list {
    grid-template-columns: repeat(1, 1fr);
  }

  .proffer {
    gap: 15px;
    align-items: end;
    flex-direction: column;
  }

  .saves {
    gap: 20px;
    flex-direction: column;
  }

  .payment__content {
    grid-template-columns: 1fr;
  }

  .profile__parent {
    position: static;
  }

  .profile__unit {
    padding: 20px;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  .hide-580 {
    display: flex;
  }

  .show-580 {
    display: none;
  }

  .button--full {
    height: 45px;
    border-radius: 0 0 5px 5px;
    width: 100%;
  }

  .content--main .content__img {
    display: none;
  }

  .content--main .content__count {
    position: static;
    background-color: var(--white);
    border: 1px solid var(--green);
  }

  .content--main .content__link {
    flex-direction: row;
    align-items: center;
    gap: 15px;
    padding: 20px 10px;
    border: 1px solid var(--grey);
  }

  .content--main .content__link:not(:last-child) {
    border-bottom: none;
  }

  .content--main .content__text {
    border: none;
    padding: 0;
  }

  .content--main .content__date {
    color: var(--green);
  }

  .widget__link {
    padding: 15px 15px 20px;
  }

  .cropper-controls {
    gap: 20px;
    flex-direction: column;
  }
}
