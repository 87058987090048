.calendar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
}

.calendar__top {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}

.calendar__years {
  display: flex;
  align-items: center;
  gap: 10px;
}

.calendar__arrow {
  width: 25px;
  height: 25px;
  background-color: var(--dark-blue);
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.calendar__arrow:hover .calendar__icon {
  fill: var(--green);
  transition: 0.3s;
}

.calendar__year {
  color: var(--green);
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  line-height: 30px;
  text-transform: uppercase;
}

.calendar__icon {
  fill: var(--white);
  margin: 0 0 0 2px;
  transition: 0.3s;
}

.calendar__arrow--left .calendar__icon {
  transform: rotate(180deg);
  margin: 0 2px 0 0;
}

.calendar__main {
  display: grid;
  grid-template-columns: 62% auto;
  align-items: center;
  gap: 20px;
  flex-grow: 1;
}

.calendar__months {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  background-color: var(--dark-blue);
  border-radius: 5px;
}

.calendar__month {
  width: 100%;
}

.calendar__link {
  display: flex;
  flex-shrink: 0;
  padding: 18px 5px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--white);
  transition: 0.3s;
}

.calendar__link:hover {
  color: var(--green);
  transition: 0.3s;
}

.calendar__month--active .calendar__link:hover {
  color: var(--white);
}

.calendar__month--active .calendar__link {
  background-color: var(--green);
  border-radius: 6px;
}

.calendar__content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.calendar__text {
  color: var(--black-2);
  font-size: 20px;
  line-height: 24px;
}

.calendar__infos {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.calendar__info {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 16px;
  line-height: 20px;
  text-transform: lowercase;
}

.calendar__circle {
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border: 1px solid var(--grey);
  border-radius: 100%;
}

.calendar__info--home .calendar__circle {
  background-color: var(--green-light);
}

.calendar__info--away .calendar__circle {
  background-color: var(--red-light-2);
}

.calendar__info--home-away .calendar__circle {
  background-color: var(--yellow-light);
}

.calendar__info--booked .calendar__circle {
  background-color: var(--blue-light);
}

.calendar__month--hidden {
  display: none;
}

.calendar__wrapper {
  width: 100%;
}

.calendar__button {
  display: none;
}

.fc .fc-daygrid-body-natural .fc-daygrid-day-events {
  margin: 0;
  min-height: auto;
  border: none;
}

.fc .fc-daygrid-day-events {
  margin: 0;
}

.fc .fc-daygrid-event {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fc .fc-daygrid-day-frame {
  min-height: 70px;
}
/* временное решение */
.fc .fc-daygrid-day-bg .fc-bg-event {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  cursor: pointer;
  transition: 0.3s;
  z-index: 0;
}

.fc .fc-daygrid-day-bg .fc-bg-event:hover {
  opacity: 1;
  transition: 0.3s;
}

.fc-theme-standard th {
  border: none;
}

.fc .fc-col-header-cell-cushion {
  padding: 7px;
  background-color: var(--green);
  width: 100%;
  height: 100%;
  color: var(--white);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  min-height: 25px;
}

.fc-event-img {
  border-radius: 100%;
  overflow: hidden;
}

.fc-day {
  background-color: var(--white);
}

.fc-day-other {
  background-color: rgba(var(--violet-opacity), 0.1);
}

.fc-theme-standard td {
  border: 1px solid var(--grey);
  position: relative;
}

.fc .fc-daygrid-day-number {
  padding: 5px;
  color: var(--dark-blue);
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  z-index: 0;
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid var(--grey);
  border-radius: 5px;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}

.fc-col-header {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

.fc .fc-scroller-harness {
  overflow: visible;
}

.event {
  display: none;
  position: absolute;
  transition: 0.3s;
  width: 390px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow:
    0 8px 24px 0 rgba(var(--grey-opacity-3), 0.12),
    0 4px 4px 0 rgba(var(--grey-opacity-3), 0.04);
}

.event:before {
  content: '';
  width: 8px;
  display: block;
  background-color: var(--white);
  flex-shrink: 0;
}

.event--home:before {
  background-color: var(--green);
}

.event--away:before {
  background-color: var(--red);
}

.event--home-away:before {
  background-color: var(--yellow);
}

.event--booked:before {
  background-color: var(--sky);
}

.event--open {
  display: flex;
  transition: 0.3s;
}

.event-modal {
  right: -20px;
  position: absolute;
  display: flex;
  align-items: center;
}

.event__list {
  background-color: var(--white);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}

.event__item {
  display: grid;
  grid-template-columns: 100px auto;
  align-items: flex-start;
  gap: 10px;
  font-size: 14px;
  line-height: 20px;
}

.event__right {
  font-weight: 700;
}

.fc-direction-ltr {
  background-color: var(--white);
  border-radius: 5px;
}

.fc .fc-scrollgrid-section-sticky > * {
  background-color: transparent;
}

.fc .fc-daygrid-bg-harness {
  z-index: 1;
}

.fc-event-container {
  display: flex;
  align-items: center;
}

.fc-day-sun .event-modal {
  flex-direction: row-reverse;
  right: 80px;
}

.fc-day-sun .event {
  flex-direction: row-reverse;
}

.fc-day-fri .event-modal,
.fc-day-sat .event-modal {
  justify-content: center;
  right: 45px;
  bottom: -70px;
}

.fc-day-wed .event-modal,
.fc-day-thu .event-modal {
  right: 0;
  justify-content: center;
  top: -70px;
}

.fc-day-fri .event,
.fc-day-thu .event,
.fc-day-wed .event,
.fc-day-sat .event {
  flex-direction: column;
}

.fc-scrollgrid-sync-table tr:nth-child(3) .fc-day-wed .event-modal,
.fc-scrollgrid-sync-table tr:nth-child(3) .fc-day-thu .event-modal {
  top: 150px;
}

.fc-scrollgrid-sync-table tr:nth-child(4) .fc-day-thu .event,
.fc-scrollgrid-sync-table tr:nth-child(4) .fc-day-wed .event {
  flex-direction: column-reverse;
}

.fc-day-fri .event:before,
.fc-day-thu .event:before,
.fc-day-wed .event:before,
.fc-day-sat .event:before {
  width: 100%;
  height: 8px;
}

.fc-scrollgrid-sync-table tr:first-child .event-modal {
  align-items: stretch;
  top: 80px;
  right: auto;
  left: 20px;
}

.fc-scrollgrid-sync-table tr:first-child .fc-day-sun .event-modal,
.fc-scrollgrid-sync-table tr:first-child .fc-day-sat .event-modal {
  flex-direction: row-reverse;
  right: 0;
  justify-content: end;
}

.fc-scrollgrid-sync-table tr:first-child .fc-day-thu .event-modal {
  justify-content: center;
}

.fc-scrollgrid-sync-table tr:last-child .event:before,
.fc-scrollgrid-sync-table tr:last-child .event:before {
  width: 100%;
  height: 8px;
}

.fc-scrollgrid-sync-table tr:first-child .event {
  flex-direction: column;
}

.fc-scrollgrid-sync-table tr:first-child .event:before {
  width: 100%;
  height: 8px;
}

.fc-scrollgrid-sync-table tr:nth-last-child(2) .fc-day-fri .event-modal,
.fc-scrollgrid-sync-table tr:nth-last-child(2) .fc-day-thu .event-modal,
.fc-scrollgrid-sync-table tr:nth-last-child(2) .fc-day-sat .event-modal {
  top: -70px;
  bottom: auto;
  left: -40px;
  justify-content: center;
}

.fc-scrollgrid-sync-table tr:nth-last-child(2) .fc-day-fri .event:before,
.fc-scrollgrid-sync-table tr:nth-last-child(2) .fc-day-thu .event:before,
.fc-scrollgrid-sync-table tr:nth-last-child(2) .fc-day-sat .event:before {
  width: 100%;
  height: 8px;
}

.fc-scrollgrid-sync-table tr:last-child .fc-day-fri .event-modal,
.fc-scrollgrid-sync-table tr:last-child .fc-day-sat .event-modal {
  top: auto;
  bottom: 80px;
  justify-content: stretch;
}

.fc-scrollgrid-sync-table tr:nth-last-child(2) .fc-day-fri .event,
.fc-scrollgrid-sync-table tr:nth-last-child(2) .fc-day-thu .event,
.fc-scrollgrid-sync-table tr:nth-last-child(2) .fc-day-sat .event {
  flex-direction: column-reverse;
}

.fc-scrollgrid-sync-table tr:last-child .fc-day-fri .event,
.fc-scrollgrid-sync-table tr:last-child .fc-day-sat .event {
  flex-direction: column-reverse;
}

.fc-scrollgrid-sync-table tr:last-child .event {
  flex-direction: column-reverse;
}

.fc-scrollgrid-sync-table tr:last-child .event-modal {
  flex-direction: column-reverse;
  right: auto;
  bottom: 80px;
  align-items: stretch;
  left: 0;
}

.fc-scrollgrid-sync-table tr:last-child .fc-day-sun .event-modal,
.fc-scrollgrid-sync-table tr:last-child .fc-day-sat .event-modal,
.fc-scrollgrid-sync-table tr:last-child .fc-day-thu .event-modal {
  align-items: end;
  right: 0;
  left: auto;
}

.fc-scrollgrid-sync-table tr:last-child .fc-day-fri .event-modal,
.fc-scrollgrid-sync-table tr:last-child .fc-day-thu .event-modal {
  align-items: center;
  justify-content: end;
}

.fc-scrollgrid-sync-table tr:last-child .fc-day-wed .event-modal {
  justify-content: end;
}

.fc-scrollgrid-sync-table tr:last-child .fc-day-wed .event-modal {
  left: -30px;
}

.fc-scrollgrid-sync-table tr:nth-child(2) .fc-day-fri .event,
.fc-scrollgrid-sync-table tr:nth-child(2) .fc-day-thu .event,
.fc-scrollgrid-sync-table tr:nth-child(2) .fc-day-wed .event,
.fc-scrollgrid-sync-table tr:nth-child(2) .fc-day-sat .event {
  flex-direction: column;
}

.fc-scrollgrid-sync-table tr:nth-child(2) .fc-day-wed .event-modal,
.fc-scrollgrid-sync-table tr:nth-child(2) .fc-day-thu .event-modal {
  top: 150px;
}

@media (max-width: 1180px) {
  .event {
    width: 360px;
  }
}

@media (max-width: 780px) {
  .calendar__months {
    grid-template-columns: repeat(10, 1fr);
  }

  .calendar__wrapper {
    display: flex;
    align-items: center;
  }

  .calendar__button {
    width: 100%;
    max-width: 45px;
    height: 30px;
    background-color: var(--green);
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 0 5px 5px 0;
  }

  .calendar__button--left {
    border-radius: 5px 0 0 5px;
  }

  .calendar__button--left .calendar__icon {
    transform: rotate(180deg);
    margin: 0 2px 0 0;
  }

  .calendar__main {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .calendar__infos {
    display: grid;
    row-gap: 10px;
    column-gap: 20px;
    grid-template-columns: auto auto;
  }

  .calendar__info:nth-child(2n) {
    order: 1;
  }

  .calendar__text {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
  }

  .calendar__link {
    padding: 13px 10px;
    font-size: 14px;
    line-height: 18px;
  }

  .fc .fc-daygrid-day-frame {
    min-height: 65px;
  }

  .calendar__content {
    gap: 25px;
  }

  .calendar {
    gap: 15px;
  }

  .calendar__year {
    font-size: 28px;
  }

  .calendar__icon {
    width: 6px;
    height: 12px;
  }

  .calendar__button .calendar__icon {
    width: 10px;
    height: 20px;
  }

  .calendar__top {
    align-items: center;
  }

  .calendar__circle {
    width: 30px;
    height: 30px;
  }

  .event__item {
    font-size: 12px;
    line-height: 16px;
  }

  .event__list {
    padding: 13px;
  }

  .event {
    width: 320px;
  }

  .event__item {
    grid-template-columns: 80px auto;
  }

  .fc-scrollgrid-sync-table tr:first-child .event-modal {
    top: 70px;
  }

  .fc-scrollgrid-sync-table tr:last-child .event-modal {
    bottom: 70px;
  }

  .fc-day-fri .event-modal,
  .fc-day-sat .event-modal {
    bottom: -70px;
  }

  .fc-day-wed .event-modal,
  .fc-day-thu .event-modal {
    top: -70px;
  }

  .fc-scrollgrid-sync-table tr:nth-child(2) .fc-day-wed .event-modal,
  .fc-scrollgrid-sync-table tr:nth-child(2) .fc-day-thu .event-modal {
    top: 130px;
  }

  .fc-scrollgrid-sync-table tr:last-child .fc-day-fri .event-modal,
  .fc-scrollgrid-sync-table tr:last-child .fc-day-sat .event-modal {
    bottom: 70px;
  }

  .fc-scrollgrid-sync-table tr:nth-child(3) .fc-day-wed .event-modal,
  .fc-scrollgrid-sync-table tr:nth-child(3) .fc-day-thu .event-modal {
    top: 130px;
  }
}

@media (max-width: 680px) {
  .calendar__months {
    grid-template-columns: repeat(8, 1fr);
  }

  .fc .fc-daygrid-day-frame {
    min-height: 60px;
  }
}

@media (max-width: 580px) {
  .calendar__months {
    grid-template-columns: repeat(6, 1fr);
  }

  .fc .fc-daygrid-day-frame {
    min-height: 55px;
  }
}

@media (max-width: 480px) {
  .calendar__months {
    grid-template-columns: repeat(6, 1fr);
  }

  .calendar__info {
    font-size: 14px;
    gap: 10px;
  }

  .calendar__link {
    padding: 12px 10px;
    font-size: 12px;
    line-height: 16px;
  }

  .calendar__content {
    gap: 20px;
  }

  .calendar {
    gap: 20px;
  }
  .calendar__arrow {
    width: 20px;
    height: 20px;
  }

  .calendar__year {
    font-size: 24px;
    line-height: 28px;
  }

  .calendar__icon {
    width: 5px;
    height: 10px;
  }

  .calendar__button .calendar__icon {
    width: 10px;
    height: 20px;
  }

  .calendar__circle {
    width: 25px;
    height: 25px;
  }

  .fc .fc-daygrid-day-frame {
    min-height: 50px;
  }

  .calendar__text {
    font-size: 16px;
    line-height: 20px;
  }

  .fc .fc-col-header-cell-cushion {
    padding: 4px;
  }

  .event__item {
    font-size: 10px;
    line-height: 14px;
  }

  .event__list {
    padding: 10px;
  }

  .event {
    width: 280px;
  }

  .event__item {
    grid-template-columns: 60px auto;
  }

  .fc-scrollgrid-sync-table tr:first-child .event-modal {
    top: 60px;
  }

  .fc-scrollgrid-sync-table tr:last-child .event-modal {
    bottom: 60px;
  }

  .fc-day-sun .event-modal {
    right: 65px;
  }

  .fc-day-fri .event-modal,
  .fc-day-sat .event-modal {
    bottom: -60px;
  }

  .fc-day-wed .event-modal,
  .fc-day-thu .event-modal {
    top: -70px;
  }

  .fc-scrollgrid-sync-table tr:nth-child(2) .fc-day-wed .event-modal,
  .fc-scrollgrid-sync-table tr:nth-child(2) .fc-day-thu .event-modal {
    top: 110px;
  }

  .fc-scrollgrid-sync-table tr:last-child .fc-day-fri .event-modal,
  .fc-scrollgrid-sync-table tr:last-child .fc-day-sat .event-modal {
    bottom: 60px;
  }

  .fc-scrollgrid-sync-table tr:nth-child(3) .fc-day-wed .event-modal,
  .fc-scrollgrid-sync-table tr:nth-child(3) .fc-day-thu .event-modal {
    top: 110px;
  }
}

@media (max-width: 390px) {
  .calendar__months {
    grid-template-columns: repeat(4, 1fr);
  }

  .calendar__circle {
    width: 20px;
    height: 20px;
  }

  .fc .fc-daygrid-day-frame {
    min-height: 45px;
  }

  .event {
    width: 240px;
  }

  .fc-day-sun .event-modal {
    right: 50px;
  }

  .fc-day-fri .event-modal,
  .fc-day-sat .event-modal {
    bottom: -50px;
  }

  .fc-day-wed .event-modal,
  .fc-day-thu .event-modal {
    top: -50px;
  }

  .fc-scrollgrid-sync-table tr:nth-child(2) .fc-day-wed .event-modal,
  .fc-scrollgrid-sync-table tr:nth-child(2) .fc-day-thu .event-modal {
    top: 100px;
  }

  .fc-scrollgrid-sync-table tr:last-child .fc-day-fri .event-modal,
  .fc-scrollgrid-sync-table tr:last-child .fc-day-sat .event-modal {
    bottom: 50px;
  }

  .fc-scrollgrid-sync-table tr:nth-child(3) .fc-day-wed .event-modal,
  .fc-scrollgrid-sync-table tr:nth-child(3) .fc-day-thu .event-modal {
    top: 100px;
  }
}
