.redactor-styles {
  font-size: 18px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--black-2);
}

.redactor-styles > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.redactor-styles p {
  margin: 0;
  padding: 0;
}

.redactor-styles blockquote {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
}

.redactor-styles figcaption {
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.redactor-styles a {
  color: var(--violet);
  transition: 0.3s;
}

.redactor-styles a:hover {
  text-decoration-line: underline;
}

.redactor-styles img {
  max-width: 100%;
  height: auto;
}

.redactor-styles ul,
.redactor-styles ol {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.redactor-styles ul li {
  display: flex;
  gap: 15px;
  align-items: baseline;
}

.redactor-styles ul li::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: var(--blue);
  flex-shrink: 0;
  border-radius: 100%;
}

.redactor-styles ol {
  counter-reset: num;
  padding-inline-start: 25px;
}

.redactor-styles ol li {
  gap: 5px;
}

.redactor-styles ol li span {
  color: var(--blue);
  min-width: 25px;
}

.redactor-styles .text-center {
  text-align: center;
}

.redactor-styles .text-right {
  text-align: right;
}

.redactor-styles .text-justify {
  text-align: justify;
}

.redactor-styles table {
  width: auto;
}

.redactor-styles table td,
.redactor-styles table th {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 12px;
  line-height: 14px;
}

.redactor-styles h1,
.redactor-styles h2,
.redactor-styles h3,
.redactor-styles h4,
.redactor-styles h5,
.redactor-styles h6 {
  font-family: 'Coil', 'Arial', sans-serif;
}

.redactor-styles h1 {
  font-size: 28px;
  line-height: 34px;
}

.redactor-styles h2 {
  font-size: 26px;
  line-height: 30px;
}

.redactor-styles h3 {
  font-weight: 700;
}

.redactor-styles h4 {
  font-size: 22px;
  line-height: 26px;
}

.redactor-styles iframe {
  max-width: 100%;
  width: 100% !important;
  height: 665px !important;
}

.redactor-styles address,
.redactor-styles blockquote,
.redactor-styles dl,
.redactor-styles figure,
.redactor-styles hr,
.redactor-styles ol,
.redactor-styles p,
.redactor-styles pre,
.redactor-styles table,
.redactor-styles ul {
  margin-bottom: 0;
  text-wrap: wrap;
}

@media (max-width: 980px) {
  .redactor-styles {
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .redactor-styles {
    font-size: 16px;
    line-height: 24px;
    gap: 10px;
  }
}
