@media (max-width: 1600px) {
  .share__socials {
    right: 130px;
    border-radius: 8px 0 0 8px;
  }

  .share__line {
    border-radius: 0 8px 8px 0;
    right: -5px;
    left: auto;
  }

  .widget__scores {
    font-size: 26px;
    line-height: 32px;
  }

  .widget__teams {
    grid-template-columns: calc((100% - 90px) / 2) 80px calc((100% - 90px) / 2);
  }

  .profile__author {
    line-height: 20px;
  }

  .profile__link {
    padding: 10px 40px;
  }
}

@media (max-width: 1440px) {
  .container {
    padding: 25px 35px 75px;
  }

  .container-x {
    padding: 0 35px;
  }

  .container-left {
    padding: 25px 0 75px 35px;
  }

  .container-row {
    padding: 0 0 0 35px;
  }

  .match__wrap {
    grid-template-columns: calc(50% - 55px) 110px calc(50% - 55px);
  }

  .header {
    grid-template-columns: 14% 63% 23%;
  }

  .footer {
    padding: 20px 35px;
  }

  .content__text {
    padding: 25px 90px 30px 35px;
  }

  .content {
    grid-template-columns: repeat(3, 1fr);
  }

  .images__icon {
    width: 55px;
  }

  .main__container {
    grid-template-columns: calc(77% - 20px) 23%;
  }

  .video-iframe iframe {
    height: 500px;
  }

  .documents {
    padding: 50px 35px;
  }

  .documents--end {
    padding: 30px 15px;
  }

  .background {
    gap: 55px;
  }

  .player__img {
    width: 140px;
  }

  .modal__background {
    padding: 40px 65px;
  }

  .matches__img {
    width: 130px;
  }

  .payment__item {
    gap: 50px;
  }

  .widget__scores {
    font-size: 24px;
    line-height: 30px;
  }

  .widget__teams {
    grid-template-columns: calc((100% - 85px) / 2) 75px calc((100% - 85px) / 2);
  }
}

@media (max-width: 1300px) {
  .container {
    padding: 25px 30px 75px;
  }

  .container-x {
    padding: 0 30px;
  }

  .container-left {
    padding: 25px 0 75px 30px;
  }

  .container-row {
    padding: 0 0 0 30px;
  }

  .match__wrap {
    grid-template-columns: calc(50% - 50px) 100px calc(50% - 50px);
  }

  .tournaments__img {
    width: 75px;
  }

  .footer {
    padding: 20px 30px;
  }

  .profile__name {
    font-size: 18px;
    line-height: 22px;
  }

  .content__text {
    padding: 25px 90px 30px 30px;
  }

  .translation__icon {
    width: 39px;
    height: 39px;
  }

  .translation__icon {
    width: 50px;
    height: 50px;
  }

  .translation__text {
    padding: 30px 35px;
  }

  .images__icon {
    width: 50px;
  }

  .video-iframe iframe {
    height: 445px;
  }

  .contact__map {
    height: 530px;
  }

  .grid--sidebar {
    grid-template-columns: 1fr;
    gap: 50px;
  }

  .documents {
    padding: 45px 30px;
  }

  .documents--end {
    padding: 30px 15px;
  }

  .players__img {
    width: 200px;
  }

  .background {
    gap: 50px;
  }

  .command__wrap {
    gap: 50px;
  }

  .player__img {
    width: 130px;
  }

  .modal__background {
    padding: 40px 55px;
  }

  .background-form {
    gap: 50px;
  }

  .background-form--profile {
    padding: 50px 20px 50px 50px;
  }

  .compound__number {
    left: -30px;
  }

  .compound__item {
    gap: 50px;
  }

  .matches__img {
    width: 120px;
  }

  .matches__name {
    font-size: 26px;
    line-height: 32px;
  }

  .matches__number {
    font-size: 86px;
    line-height: 104px;
    min-width: 104px;
  }

  .game__grid {
    grid-template-columns: 1fr 110px 1fr;
  }

  .payment__pay {
    gap: 50px;
  }

  .payment__item {
    grid-template-columns: 1fr 35%;
  }

  .payment__item {
    gap: 40px;
  }

  .mistake {
    gap: 25px;
    padding: 90px 30px;
  }

  .filter--matches {
    grid-template-columns: repeat(2, 215px);
  }

  .widget__scores {
    font-size: 22px;
    line-height: 28px;
  }

  .widget__teams {
    grid-template-columns: calc((100% - 80px) / 2) 70px calc((100% - 80px) / 2);
  }

  .widget__infos {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 1180px) {
  .container {
    padding: 25px 25px 75px;
  }

  .container-x {
    padding: 0 25px;
  }

  .container-left {
    padding: 25px 0 75px 25px;
  }

  .container-row {
    padding: 0 0 0 25px;
  }

  .match__team {
    padding: 10px 10px 10px 20px;
  }

  .match__team.match__end {
    padding: 10px 20px 10px 10px;
  }

  .match__wrap {
    grid-template-columns: calc(50% - 45px) 90px calc(50% - 45px);
  }

  .tournaments__img {
    width: 70px;
  }

  .logo--header .logo__icon {
    width: 105px;
  }

  .footer {
    padding: 20px 25px;
  }

  .social__link {
    width: 40px;
    height: 40px;
  }

  .profile__name {
    font-size: 16px;
    line-height: 20px;
  }

  .content__text {
    padding: 25px 90px 30px 25px;
  }

  .content__icons {
    right: 25px;
  }

  .translation__icon {
    width: 44px;
    height: 44px;
  }

  .translation__text {
    font-size: 22px;
    line-height: 26px;
    padding: 25px 30px;
  }

  .images__icon {
    width: 45px;
  }

  .video-iframe iframe {
    height: 390px;
  }

  .grid {
    grid-template-columns: 1fr;
    gap: 50px;
  }

  .documents--end .documents__texts {
    align-items: start;
    order: 1;
  }

  .documents {
    padding: 40px 25px;
  }

  .documents--end {
    margin: 0 -25px;
    align-items: start;
    padding: 30px 15px;
  }

  .contact__map {
    height: 440px;
  }

  .contact__block {
    padding: 25px;
  }

  .players__img {
    width: 190px;
  }

  .background {
    padding: 50px 30px 65px;
    gap: 45px;
  }

  .background--command {
    padding: 30px 20px 35px;
  }

  .command__block {
    gap: 15px;
  }

  .command__wrap {
    gap: 45px;
  }

  .player__img {
    width: 120px;
  }

  .command__item {
    grid-template-columns: 95px 1fr;
  }

  .command {
    gap: 30px;
  }

  .modal__background {
    padding: 40px 45px;
  }

  .background-form {
    padding: 45px 20px;
    gap: 45px;
  }

  .compound__number {
    left: -25px;
  }

  .background-form--profile {
    padding: 45px 20px 45px 45px;
  }

  .compound__item {
    gap: 45px;
  }

  .matches__img {
    width: 110px;
  }

  .matches__name {
    font-size: 24px;
    line-height: 28px;
  }

  .matches__number {
    font-size: 76px;
    line-height: 92px;
    min-width: 92px;
  }

  .matches__colon {
    font-size: 42px;
    line-height: 48px;
  }

  .matches__container {
    padding: 15px 25px 30px;
  }

  .game__number {
    width: 40px;
  }

  .game__grid {
    grid-template-columns: 1fr 100px 1fr;
  }

  .payment__pay {
    gap: 40px;
  }

  .save--pay {
    max-width: 180px;
  }

  .payment__item {
    gap: 35px;
  }

  .mistake {
    gap: 20px;
    padding: 80px 30px;
  }

  .filter--matches {
    grid-template-columns: repeat(2, 185px);
  }

  .widget__link {
    padding: 30px 20px 20px;
  }

  .widget__scores {
    font-size: 20px;
    line-height: 26px;
  }

  .widget__teams {
    grid-template-columns: calc((100% - 75px) / 2) 65px calc((100% - 75px) / 2);
  }

  .widget__name {
    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 1080px) {
  .container-left {
    padding: 25px 25px 75px;
  }

  .container-row {
    padding: 0 25px;
  }

  .match__date {
    align-items: start;
    flex-direction: column;
  }

  .tournaments {
    grid-template-columns: 1fr;
  }

  .footer {
    padding: 20px;
  }

  .header {
    grid-template-columns: auto 29% 70px;
    height: 70px;
  }

  .navigation {
    order: 1;
    align-items: center;
    justify-content: center;
  }

  .navigation__list {
    display: none;
    transition: 0.3s;
  }

  .burger {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--green);
  }

  .burger__container {
    display: flex;
    width: 30px;
    height: 20px;
    align-items: center;
    justify-content: center;
  }

  .burger span {
    width: 100%;
    background-color: var(--white);
    height: 3px;
    display: block;
    position: relative;
    border-radius: 2px;
  }

  .burger span:after,
  .burger span:before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--white);
    border-radius: 2px;
  }

  .burger span:after {
    bottom: -9px;
  }

  .burger span:before {
    top: -9px;
  }

  .burger--open span {
    background: transparent;
  }

  .burger--open span:before,
  .burger--open span:after {
    transition-duration: 0.2s, 0.2s;
    transition-delay: 0.2s, 0s;
  }

  .burger--open span:before {
    transition-property: top, transform;
  }

  .burger--open span:after {
    transition-property: bottom, transform;
  }

  .burger--open span:before {
    top: 0;
    transform: rotate(45deg);
  }

  .burger--open span:after {
    bottom: 0;
    transform: rotate(-45deg);
  }

  .burger--open span:before,
  .burger--open span:after {
    transition-delay: 0s, 0.2s;
  }

  .navigation__list::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }

  .navigation__list {
    position: absolute;
    flex-direction: column;
    top: 100%;
    left: 0;
    background-color: var(--white);
    z-index: 2;
    overflow: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    height: calc(100vh - 140px);
  }

  .burger--open ~ .navigation__list {
    display: flex;
    transition: 0.3s;
  }

  .navigation__item {
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid var(--grey);
    border-right: 1px solid var(--grey);
    border-left: 1px solid var(--grey);
  }

  .navigation__item.navigation__item--open {
    border-bottom: none;
  }

  .navigation__item--active .navigation__link {
    border: none;
  }

  .nav__item--active .nav__link {
    background-color: var(--white);
  }

  .navigation__item.navigation__item--open .navigation__icon {
    transform: rotate(180deg);
  }

  .nav.nav--active {
    transition: 0.3s;
    display: block;
  }

  .navigation__link {
    padding: 20px;
  }

  .navigation__item.navigation__item--open .navigation__link {
    border-bottom: none;
  }

  .nav {
    border-top: 4px solid var(--green);
    border-bottom: 4px solid var(--green);
  }

  .nav.nav--active {
    position: static;
  }

  .nav__link {
    padding: 12px;
    border-bottom: none;
    border-right: none;
    border-left: none;
    background-color: rgba(var(--green-opacity), 0.2);
  }

  .hide-1080 {
    display: none;
  }

  .show-1080 {
    display: flex;
  }

  .images {
    width: 100%;
  }

  .main__container {
    grid-template-columns: 100%;
    gap: 0;
  }

  .sidebar {
    margin: -10px 0 0;
  }

  .next__text {
    font-size: 20px;
  }

  .sidebar__block {
    padding: 10px 15px;
  }

  .sidebar__icon {
    width: 30px;
  }

  .sidebar__count {
    padding: 14px;
  }

  .sidebar__date {
    font-size: 14px;
    line-height: 16px;
  }

  .images__picture {
    width: 100%;
  }

  .sidebar__list {
    grid-template-columns: repeat(3, 1fr);
  }

  .content {
    grid-template-columns: repeat(2, 1fr);
  }

  .video-iframe iframe {
    height: 500px;
  }

  .contact {
    margin: 0 -25px;
  }

  .contact__map {
    height: 600px;
  }

  .sidebar-banner {
    padding: 0 25px 75px;
    margin: -25px 0 0;
  }

  .tourney__wrap {
    padding: 15px 30px 15px 15px;
  }

  .tourney__background {
    left: auto;
    top: auto;
  }

  .documents {
    margin: 0 -25px;
  }

  .players__img {
    width: 180px;
  }

  .meet__grid--green {
    display: none;
  }

  .meet {
    border-radius: 0 0 5px 5px;
  }

  .meet__grid {
    border-radius: 0 0 5px 5px;
  }

  .meet__parent {
    grid-template-columns: calc(72% - 10px) 28%;
    row-gap: 0;
  }

  .meet__block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 10px;
    grid-row: 2/2;
    grid-column: -3/-1;
    align-items: end;
    background-color: var(--milky);
  }

  .meet__content {
    background-color: var(--blue);
    padding: 10px;
    height: 100%;
  }

  .hide-980 {
    display: none;
  }

  .command__block {
    gap: 10px;
  }

  .grid-profile {
    grid-template-columns: 100%;
    gap: 25px;
  }

  .blocks {
    border-radius: 0;
    background-color: transparent;
    padding: 25px 0 0;
  }

  .blocks__background {
    display: none;
  }

  .search--end {
    width: 100%;
  }

  .command__list {
    width: auto;
  }

  .command__wrap {
    gap: 40px;
  }

  .player-scroll {
    height: 100%;
  }

  .efficiency--players {
    width: 45px;
    height: 45px;
    right: 0;
  }

  .buttons--border {
    border-radius: 6px;
  }

  .buttons--border .buttons__item--active .buttons__link {
    border-radius: 6px;
  }

  .player__img {
    width: 110px;
  }

  .modal__background {
    padding: 40px 35px;
  }

  .background-form {
    gap: 40px;
  }

  .compound__item {
    gap: 40px;
  }

  .clue {
    justify-content: start;
  }

  .matches__img {
    width: 100px;
  }

  .matches__name {
    font-size: 22px;
    line-height: 26px;
  }

  .matches__number {
    font-size: 66px;
    line-height: 80px;
    min-width: 80px;
  }

  .matches__colon {
    font-size: 38px;
    line-height: 42px;
  }

  .game__container {
    gap: 25px;
  }

  .game__container {
    padding: 15px 15px 15px 25px;
    gap: 20px;
  }

  .game__container--right {
    padding: 15px 25px 15px 15px;
  }

  .game__number {
    width: 35px;
    height: 35px;
  }

  .game__grid {
    grid-template-columns: 1fr 90px 1fr;
  }

  .payment__price {
    font-size: 26px;
  }

  .save--pay {
    max-width: 170px;
  }

  .payment {
    gap: 25px;
  }

  .mistake__number {
    font-size: 130px;
    line-height: 155px;
  }

  .mistake {
    gap: 15px;
    padding: 70px 25px;
  }

  .filter--matches {
    grid-template-columns: repeat(2, 230px);
  }

  .table__big {
    font-size: 24px;
    line-height: 28px;
  }

  .table__300 {
    min-width: 200px;
  }

  .table__widget {
    min-width: 130px;
  }
}

@media (min-width: 1081px) {
  .navigation__item--open .navigation__icon {
    transform: rotate(180deg);
  }

  .navigation__item--open .nav {
    transition: 0.3s;
    display: flex;
  }

  .nav__item--active ~ .navigation__item {
    border-bottom: 2px solid var(--green);
    transition: 0.3s;
  }

  .navigation__item--open .navigation__link {
    border-bottom: 2px solid var(--green);
    transition: 0.3s;
  }
}

@media (max-width: 980px) {
  .container {
    padding: 25px 20px 75px;
  }

  .container-x {
    padding: 0 20px;
  }

  .container-left {
    padding: 25px 20px 75px;
  }

  .container-row {
    padding: 0 20px;
  }

  .filter--league {
    grid-template-columns: repeat(3, 1fr);
  }

  .league {
    grid-template-columns: repeat(3, 1fr);
  }

  .table__127 {
    min-width: 90px;
  }

  .match__img {
    width: 35px;
  }

  .match__number {
    font-size: 18px;
    line-height: 20px;
  }

  .match__wrap {
    grid-template-columns: 1fr 20% 1fr;
  }

  .match__wrap {
    grid-template-columns: calc(50% - 40px) 80px calc(50% - 40px);
  }

  .match {
    border-radius: 0;
  }

  .match__grid:not(.match__item .match__grid) {
    display: none;
  }

  .match__team {
    flex-direction: column;
    align-items: start;
    padding: 10px 10px 10px 15px;
  }

  .match__end.match__team {
    align-items: end;
    padding: 10px 15px 10px 10px;
  }

  .match__end .match__img {
    order: -1;
  }

  .match__link {
    grid-template-columns: 1fr;
  }

  .match__data {
    justify-content: center;
  }

  .match__date {
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: var(--milky);
    border-bottom: 1px solid var(--grey);
  }

  .match__wrap {
    border-bottom: 1px solid var(--grey);
  }

  .tournaments__img {
    width: 65px;
  }

  .content__icons {
    right: 20px;
  }

  .content__count {
    width: 70px;
    height: 70px;
  }

  .content__text {
    padding: 20px 90px 30px 20px;
  }

  .translation__icon {
    width: 34px;
    height: 34px;
  }

  .translation__text {
    font-size: 20px;
    line-height: 24px;
    padding: 20px 25px;
  }

  .translation__icons {
    padding: 15px;
  }

  .logo--header .logo__icon {
    width: 100px;
  }

  .logo--footer .logo__icon {
    width: 130px;
  }

  .social__link {
    width: 35px;
    height: 35px;
  }

  .images__icon {
    width: 40px;
  }

  .sidebar {
    margin: -15px 0 0;
  }

  .sidebar__block {
    right: 30px;
  }

  .sidebar__count {
    right: 30px;
  }

  .sidebar__text {
    padding: 15px 50px 20px 20px;
  }

  .sidebar__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .documents {
    margin: 0 -20px;
    padding: 35px 20px;
  }

  .documents--end {
    padding: 30px 15px;
  }

  .documents__text {
    text-align: start;
  }

  .documents__icon {
    width: 35px;
  }

  .contact__map {
    height: 530px;
  }

  .contact {
    margin: 0 -20px;
  }

  .sidebar-banner {
    padding: 0 20px 75px;
  }

  .tourney__wrap {
    padding: 15px 25px 15px 15px;
  }

  .contact__block {
    padding: 25px 20px;
  }

  .accordion__block {
    padding: 10px 20px;
  }

  .gap--filter {
    flex-direction: column-reverse;
  }

  .filter--commands {
    max-width: none;
  }

  .filter--players {
    max-width: none;
  }

  .amount {
    line-height: 30px;
  }

  .players__img {
    width: 170px;
  }

  .players__name {
    font-size: 24px;
    line-height: 28px;
  }

  .background {
    padding: 50px 30px 60px;
    gap: 40px;
  }

  .background--command {
    padding: 30px 20px;
  }

  .command__wrap {
    gap: 35px;
  }

  .command__title {
    font-size: 24px;
    line-height: 28px;
  }

  .player__full {
    font-size: 26px;
  }

  .player__img {
    width: 100px;
  }

  .efficiency__number {
    font-size: 14px;
    line-height: 18px;
  }

  .efficiency--players {
    width: 40px;
    height: 40px;
    right: 0;
  }

  .command__item {
    grid-template-columns: 90px 1fr;
  }

  .command {
    gap: 25px;
  }

  .modal__background {
    padding: 35px 25px;
  }

  .form__input {
    line-height: 24px;
  }

  .modal__text {
    margin: 0 -10px;
  }

  .modal__button {
    font-size: 20px;
  }

  .background-form {
    padding: 40px 20px;
    gap: 35px;
    flex-direction: column;
  }

  .compound__number {
    left: 0;
  }

  .compound__item {
    gap: 35px;
    flex-direction: column;
  }

  .matches__img {
    width: 90px;
  }

  .matches__name {
    font-size: 20px;
    line-height: 24px;
  }

  .matches__wrap {
    gap: 15px;
    align-items: baseline;
  }

  .matches__number {
    font-size: 56px;
    line-height: 68px;
    min-width: 68px;
  }

  .matches__colon {
    font-size: 34px;
    line-height: 38px;
  }

  .matches__player {
    align-items: start;
    flex-direction: column;
    justify-content: center;
  }

  .matches__player--right .matches__name {
    order: 0;
  }

  .matches__name {
    align-items: start;
  }

  .matches__player--right {
    align-items: end;
  }

  .matches__container {
    padding: 15px 20px 30px;
    gap: 25px;
  }

  .share {
    right: 25px;
  }

  .game__container {
    padding: 15px 15px 15px 20px;
    gap: 15px;
  }

  .game__container--right {
    padding: 15px 20px 15px 15px;
  }

  .game__number {
    width: 30px;
    height: 30px;
  }

  .game__scores {
    font-size: 18px;
    line-height: 20px;
  }

  .game__point {
    width: 20px;
    height: 20px;
  }

  .game__victory {
    color: var(--white);
    font-size: 12px;
    line-height: 14px;
    padding: 4px 8px;
    right: -60px;
  }

  .game__container--right .game__victory {
    left: -60px;
  }

  .game__grid {
    grid-template-columns: 1fr 80px 1fr;
  }

  .personal__text {
    font-size: 20px;
    line-height: 28px;
  }

  .payment__item {
    grid-template-columns: 1fr;
  }

  .payment__price {
    font-size: 24px;
  }

  .save--pay {
    max-width: 160px;
    line-height: 28px;
  }

  .payment__title {
    line-height: 30px;
  }

  .payment__pay {
    justify-content: space-between;
  }

  .payment {
    gap: 30px;
  }

  .payment__item {
    gap: 30px;
  }

  .mistake__number {
    font-size: 120px;
    line-height: 140px;
  }

  .mistake {
    gap: 10px;
    padding: 60px 20px;
  }

  .verification {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 880px) {
  .logo--footer .logo__icon {
    width: 115px;
  }

  .gap--row {
    flex-direction: column;
  }

  .matches__img {
    width: 80px;
  }

  .gap--row-3 {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter--matches {
    grid-template-columns: repeat(2, 1fr);
  }

  .gap--grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .clue__link {
    text-align: end;
  }
}
