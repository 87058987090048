.cropper-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--violet-2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cropper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cropper-container {
  position: relative;
  width: 100%;
  height: 80%;
  background: var(--dark-blue);
}

.cropper-controls {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

.cropper-zoom-range {
  -webkit-appearance: none;
  appearance: none;
  width: 70%;
  height: 5px;
  background: var(--white);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.cropper-zoom-range:hover {
  opacity: 1;
}

.cropper-zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--green);
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
}

.cropper-zoom-range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4caf50;
  cursor: pointer;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
}
