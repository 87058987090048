.fancybox__backdrop {
  opacity: 1 !important;
  background-color: rgba(var(--violet-opacity), 0.9) !important;
  will-change: opacity !important;
  backdrop-filter: blur(8px) !important;
}

.fancybox__nav .f-button:before {
  display: none;
}

.fancybox__footer {
  display: none !important;
}

.fancybox__slide.has-image > .fancybox__content {
  position: relative;
}

.is-horizontal .fancybox__nav .f-button {
  width: 80px;
  height: 80px;
  background-color: var(--green);
  border-radius: 100%;
}

.is-horizontal .fancybox__nav .f-button:hover {
  background-color: var(--dark-green);
}

.fancybox__nav .f-button svg {
  width: 35px;
  height: auto;
  fill: none !important;
  stroke: var(--white) !important;
  z-index: 2;
  filter: none;
}

.fancybox__nav:hover .f-button svg {
  stroke: var(--white) !important;
}

.f-thumbs__viewport {
  display: none;
}

.fancybox__toolbar__column.is-right .f-button:not(:last-child) {
  display: none;
}

.fancybox__infobar {
  display: inline-flex;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--green);
  border-radius: 4px;
  color: var(--white);
  font-size: 22px;
  line-height: 26px;
}

.fancybox__toolbar.is-absolute,
.is-compact .fancybox__toolbar {
  position: static;
}

.fancybox__toolbar {
  text-shadow: none;
}

.fancybox__infobar span {
  padding: 0;
}

.fancybox__toolbar__column {
  justify-content: center;
  position: absolute;
}

.fancybox__toolbar__column.is-left {
  width: 100%;
  bottom: 30px;
}

.fancybox__toolbar__column.is-right {
  align-items: center;
  justify-content: end;
  width: 100%;
}

.fancybox__toolbar__column.is-right .f-button {
  width: 46px;
  height: 46px;
  color: var(--white);
  background-color: var(--green);
  margin: 50px;
  padding: 10px;
  border-radius: 100%;
}

.fancybox__toolbar__column.is-right .f-button:hover {
  background-color: var(--dark-green);
}

.is-horizontal .f-carousel__nav .f-button.is-next,
.is-horizontal .fancybox__nav .f-button.is-next {
  right: 10px;
}

.is-horizontal .f-carousel__nav .f-button.is-next,
.is-horizontal .fancybox__nav .f-button.is-prev {
  left: 10px;
}

.fancybox__toolbar__column.is-right .f-button svg {
  width: 100%;
  height: 100%;
}

.fancybox__slide.has-image > .fancybox__content {
  padding: 0 10px;
}

.fancybox__slide {
  justify-content: center;
}

.fancybox__slide::before,
.fancybox__slide::after {
  display: none;
}

.f-carousel__nav .f-button:disabled,
.fancybox__nav .f-button:disabled {
  pointer-events: auto;
}

@media (max-width: 1300px) {
  .is-horizontal .fancybox__nav .f-button {
    width: 70px;
    height: 70px;
  }

  .fancybox__infobar {
    font-size: 20px;
    line-height: 22px;
  }
}

@media (max-width: 1180px) {
  .is-horizontal .fancybox__nav .f-button {
    width: 60px;
    height: 60px;
  }

  .fancybox__infobar {
    font-size: 18px;
    line-height: 20px;
  }

  .fancybox__toolbar__column.is-right .f-button {
    margin: 40px;
  }
}

@media (max-width: 980px) {
  .is-horizontal .fancybox__nav .f-button {
    width: 50px;
    height: 50px;
  }

  .fancybox__nav .f-button svg {
    width: 30px;
  }

  .fancybox__infobar {
    font-size: 16px;
    line-height: 18px;
  }

  .fancybox__toolbar__column.is-right .f-button {
    width: 40px;
    height: 40px;
    margin: 30px;
  }
}

@media (max-width: 780px) {
  .is-horizontal .fancybox__nav .f-button {
    width: 45px;
    height: 45px;
  }

  .fancybox__nav .f-button svg {
    width: 25px;
  }

  .fancybox__infobar {
    padding: 10px 15px;
    font-size: 14px;
    line-height: 16px;
  }

  .fancybox__toolbar__column.is-right .f-button {
    width: 35px;
    height: 35px;
    margin: 25px;
  }
}

@media (max-width: 480px) {
  .is-horizontal .fancybox__nav .f-button {
    width: 40px;
    height: 40px;
  }

  .fancybox__nav .f-button svg {
    width: 20px;
  }

  .fancybox__infobar {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
  }

  .fancybox__toolbar__column.is-right .f-button {
    width: 30px;
    height: 30px;
    margin: 20px;
  }
}
