@media (max-width: 480px) {
  .container {
    padding: 15px 10px 75px;
  }

  .container-x {
    padding: 0 10px;
  }

  .container-y {
    padding: 15px 0 75px;
  }

  .container-left {
    padding: 15px 10px 75px;
  }

  .container-row {
    padding: 0 10px;
  }

  .title {
    text-align: center;
    width: 100%;
  }

  .gap--40 {
    gap: 30px;
  }

  .gap--30 {
    gap: 20px;
  }

  .gap--20 {
    gap: 15px;
  }

  .filter--league {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 15px;
  }

  .filter--league .filter__item:nth-child(1) {
    grid-column: 1/4;
  }

  .filter--league .filter__item:nth-child(2) {
    grid-column: 1/4;
  }

  .filter--league .filter__item:nth-child(3) {
    grid-column: 1/4;
  }

  .filter--league .filter__item:nth-child(4) {
    grid-column: 1/3;
  }

  .filter--league .filter__item:nth-child(5) {
    grid-column: auto;
  }

  .filter--commands {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 15px;
  }

  .filter--commands .filter__item:nth-child(1) {
    grid-column: 1/4;
  }

  .filter--commands .filter__item:nth-child(2) {
    grid-column: 1/4;
  }

  .filter--commands .filter__item:nth-child(3) {
    grid-column: 1/3;
  }

  .filter--commands .filter__item:nth-child(4) {
    grid-column: auto;
  }

  .filter--players {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
    width: 100%;
  }

  .filter--players .filter__item:nth-child(1) {
    grid-column: 1/4;
  }

  .filter--players .filter__item:nth-child(2) {
    grid-column: 1/3;
  }

  .filter--players .filter__item:nth-child(3) {
    grid-column: auto;
  }

  .tabs__button {
    font-size: 16px;
  }

  .sorting {
    gap: 5px;
  }

  .chess__wrapper {
    grid-template-columns: 55% auto;
  }

  .match__text {
    font-size: 12px;
    line-height: 14px;
  }

  .match__data--milky .match__text {
    font-size: 16px;
    line-height: 20px;
  }

  .match__team {
    padding: 10px 5px;
    gap: 5px;
  }

  .match__name {
    font-size: 16px;
    line-height: 18px;
  }

  .match__img {
    width: 25px;
  }

  .match__number {
    font-size: 14px;
    line-height: 16px;
    color: var(--black-2);
  }

  .match__dot {
    font-size: 16px;
    color: var(--black-2);
  }

  .match__win {
    color: var(--green);
  }

  .match__wrap {
    grid-template-columns: calc(50% - 30px) 60px calc(50% - 30px);
  }

  .match__date {
    padding: 5px;
  }

  .match__data {
    padding: 5px;
  }

  .match__end.match__team {
    padding: 10px 5px;
  }

  .logo--header .logo__icon {
    width: 90px;
  }

  .header {
    height: 61px;
    grid-template-columns: auto 110px 70px;
  }

  .profile__link {
    padding: 10px 35px;
  }

  .profile__img {
    width: 40px;
  }

  .stub__text {
    font-size: 18px;
    line-height: 22px;
  }

  .filter--tournaments {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 15px;
  }

  .filter--tournaments .filter__item:nth-child(1) {
    grid-column: 1/4;
  }

  .filter--tournaments .filter__item:nth-child(2) {
    grid-column: 1/4;
  }

  .filter--tournaments .filter__item:nth-child(3) {
    grid-column: 1/3;
  }

  .filter--tournaments .filter__item:nth-child(4) {
    grid-column: auto;
  }

  .tournaments__name {
    font-size: 18px;
    line-height: 22px;
  }

  .tournaments__item {
    padding: 15px;
  }

  .tournaments__texts {
    align-items: start;
  }

  .tournaments__blue {
    color: var(--dark-blue);
  }

  .tournaments__icon {
    fill: var(--dark-blue);
  }

  .hide-480 {
    display: flex;
  }

  .show-480 {
    display: none;
  }

  .tournament__item {
    gap: 10px;
  }

  .tournaments__wrap {
    gap: 20px;
  }

  .tournaments__img {
    width: 55px;
  }

  .tournaments__block {
    gap: 15px;
  }

  .tournaments__season {
    color: var(--dark-blue);
  }

  .logo--footer .logo__icon {
    width: 70px;
  }

  .social__link {
    width: 26px;
    height: 26px;
  }

  .footer {
    gap: 25px;
    padding: 20px 10px;
  }

  .footer__content {
    max-width: 270px;
    margin: 0 auto;
  }

  .footer__copyright {
    font-size: 10px;
  }

  .social__icon {
    width: 10px;
    height: auto;
  }

  .social {
    gap: 10px;
  }

  .footer__content::before {
    margin: 0 0 72px;
  }

  .content__count {
    width: 60px;
    height: 60px;
    right: 10px;
  }

  .content__date {
    font-size: 14px;
    line-height: 16px;
  }

  .content__text {
    font-size: 16px;
    line-height: 20px;
    padding: 10px 80px 20px 10px;
  }

  .content__icons {
    right: 10px;
  }

  .translation__icons {
    padding: 8px;
  }

  .translation__icon {
    width: 24px;
    height: 24px;
  }

  .translation__text {
    font-size: 16px;
    line-height: 20px;
    padding: 10px 15px;
    min-height: 50px;
  }

  .author {
    gap: 5px;
  }

  .author__name {
    font-size: 14px;
    line-height: 16px;
    display: flex;
    gap: 2px;
  }

  .author__info {
    font-size: 14px;
    line-height: 16px;
    gap: 5px;
  }

  .images__block {
    padding: 10px;
  }

  .images__icon {
    width: 30px;
  }

  .sidebar {
    margin: -25px 0 0;
  }

  .sidebar__block {
    right: 10px;
  }

  .sidebar__count {
    right: 10px;
  }

  .sidebar__text {
    padding: 10px 70px 20px 10px;
  }

  .album {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .arrows {
    gap: 30px;
  }

  .hashtag {
    gap: 10px;
  }

  .video-iframe iframe {
    height: 258px;
  }

  .hashtag__link {
    font-size: 14px;
    line-height: 16px;
  }

  .pagination--arrows {
    gap: 30px;
  }

  .documents {
    margin: 0 -10px;
    gap: 15px;
    padding: 25px 10px;
  }

  .documents--end {
    padding: 30px 10px;
  }

  .documents__item {
    gap: 10px;
  }

  .documents__text {
    font-size: 16px;
    line-height: 20px;
  }

  .documents__icon {
    width: 25px;
  }

  .contact__map {
    height: 220px;
  }

  .contact {
    margin: 0 -10px;
  }

  .sidebar-banner {
    padding: 0 10px 75px;
  }

  .tourney__wrap {
    padding: 15px;
  }

  .contact__block {
    padding: 20px 10px;
  }

  .contact__list {
    gap: 15px;
  }

  .accordion__title {
    font-size: 16px;
    line-height: 20px;
  }

  .accordion__block {
    padding: 5px 10px;
    gap: 15px;
  }

  .accordion {
    gap: 15px;
  }

  .accordion__wrap {
    padding: 15px 10px;
    gap: 10px;
  }

  .legal__text {
    font-size: 16px;
    line-height: 20px;
  }

  .amount {
    line-height: 22px;
  }

  .players__img {
    width: 150px;
  }

  .efficiency {
    width: 40px;
    height: 40px;
    right: 5px;
  }

  .players__name {
    font-size: 20px;
    line-height: 24px;
  }

  .background {
    padding: 50px 20px;
    gap: 30px;
  }

  .background--command {
    padding: 20px;
  }

  .table__121 {
    min-width: auto;
  }

  .table__auto {
    width: auto;
    min-width: auto;
  }

  .meet__content {
    gap: 10px;
  }

  .command__wrap {
    gap: 25px;
  }

  .command__list {
    width: 100%;
  }

  .command__name {
    font-size: 16px;
    line-height: 20px;
  }

  .command__subtitle {
    font-size: 8px;
    line-height: 10px;
  }

  .command__icon {
    width: 27px;
  }

  .command__number {
    font-size: 18px;
    line-height: 22px;
  }

  .command {
    gap: 15px;
  }

  .command__title {
    font-size: 20px;
    line-height: 24px;
  }

  .player__full {
    font-size: 22px;
    line-height: 28px;
    gap: 5px;
  }

  .player {
    gap: 15px;
  }

  .player__img {
    width: 80px;
  }

  .player__link {
    gap: 15px;
  }

  .efficiency--players {
    width: 30px;
    height: 30px;
    right: 0;
  }

  .efficiency__number {
    font-size: 10px;
    line-height: 14px;
  }

  .meet__img {
    display: none;
  }

  .meet__slash {
    height: 100%;
    align-self: center;
    display: block;
  }

  .meet__players {
    display: flex;
  }

  .command__item {
    grid-template-columns: 80px 1fr;
  }

  .command__text {
    font-size: 16px;
    line-height: 18px;
  }

  .command__link {
    font-size: 16px;
    line-height: 18px;
  }

  .modal__background {
    border-radius: 10px 0 10px 10px;
    padding: 25px 10px;
  }

  .modal__scroll {
    border-radius: 10px 0 10px 10px;
  }

  .form__input {
    font-size: 14px;
    line-height: 20px;
  }

  .form__text {
    font-size: 12px;
    gap: 5px;
  }

  .modal__text {
    font-size: 14px;
  }

  .modal__button {
    font-size: 16px;
  }
  .modal__button--uppercase {
    text-transform: capitalize;
  }

  .modal__close {
    width: 40px;
  }

  .background-form {
    padding: 30px 10px;
    gap: 25px;
    border-radius: 10px;
  }

  .compound__item {
    gap: 25px;
  }

  .form__text {
    font-size: 12px;
    line-height: 14px;
  }

  .form__input {
    font-size: 14px;
    line-height: 20px;
    min-height: 40px;
  }

  .form__input::placeholder {
    font-size: 14px;
    line-height: 20px;
  }

  .select--form .select__control {
    min-height: 40px !important;
  }

  .proffer {
    gap: 10px;
  }

  .saves {
    gap: 15px;
  }

  .compound {
    gap: 40px;
  }

  .save {
    font-size: 16px;
  }

  .reason {
    gap: 5px;
    text-align: center;
  }

  .reason__title {
    font-size: 16px;
  }

  .reason__subtitle {
    font-size: 16px;
    line-height: 20px;
  }

  .save--disabled {
    margin: 0;
  }

  .avatar {
    gap: 15px;
  }

  .matches__img {
    width: 50px;
  }

  .matches__name {
    font-size: 14px;
    line-height: 18px;
  }

  .matches__subtitle {
    font-size: 12px;
    line-height: 14px;
  }

  .matches__data {
    font-size: 14px;
    line-height: 20px;
  }

  .matches__number {
    font-size: 36px;
    line-height: 44px;
    min-width: 44px;
  }

  .matches__colon {
    font-size: 26px;
    line-height: 30px;
  }

  .matches__player {
    gap: 10px;
  }
  .matches__wrap {
    grid-template-columns: calc(37% - 10px) 26% calc(37% - 10px);
    gap: 5px;
  }

  .matches__container {
    padding: 15px 10px 30px;
  }

  .share {
    right: 15px;
  }

  .game__container {
    padding: 15px 5px 15px 10px;
    gap: 5px;
  }

  .game__container--right {
    padding: 15px 10px 15px 5px;
  }

  .game__text {
    font-size: 16px;
    line-height: 18px;
  }

  .game__number {
    width: 20px;
    height: 20px;
  }

  .game__scores {
    font-size: 14px;
    line-height: 16px;
  }

  .game__players {
    gap: 10px;
  }

  .game__block {
    gap: 10px;
    padding: 12px 5px;
  }

  .game__point {
    width: 10px;
    height: 10px;
  }

  .game__victory {
    color: var(--white);
    font-size: 8px;
    line-height: 10px;
    padding: 2px 4px;
    right: -40px;
  }

  .game__container--right .game__victory {
    left: -40px;
  }

  .game__grid {
    grid-template-columns: 1fr 60px 1fr;
  }

  .game__head {
    padding: 5px;
    gap: 0;
  }

  .personal__text {
    font-size: 16px;
    line-height: 20px;
  }

  .personal__link {
    max-width: none;
    font-size: 16px;
  }

  .payment__price {
    font-size: 20px;
    line-height: 26px;
  }

  .save--pay {
    max-width: 140px;
    line-height: 24px;
  }

  .payment__title {
    font-size: 20px;
    line-height: 22px;
    align-items: start;
  }

  .share__socials {
    padding: 5px 12px;
  }

  .share__icon {
    width: 10px;
  }

  .share__social {
    width: 25px;
    height: 25px;
  }

  .payment {
    gap: 40px;
  }

  .payment__item {
    gap: 20px;
  }

  .mistake__number {
    font-size: 100px;
    line-height: 104px;
  }

  .mistake {
    gap: 0;
    padding: 40px 10px;
  }

  .mistake__title {
    font-size: 18px;
  }

  .mistake__subtitle {
    font-size: 16px;
    line-height: 22px;
  }

  .profile__unit {
    font-size: 14px;
    line-height: 18px;
    justify-content: center;
  }

  .verification {
    font-size: 16px;
    line-height: 22px;
  }

  .table__big {
    font-size: 20px;
    line-height: 26px;
  }

  .widget__link {
    padding: 10px 10px 20px;
    gap: 10px;
  }

  .widget__infos {
    order: -1;
  }

  .widget__logo {
    display: none;
  }
}
@media (max-width: 400px) {
  .chess__wrapper {
    grid-template-columns: 60% auto;
  }

  .table__cell {
    padding: 5px;
  }

  .table__cell {
    font-size: 14px;
  }

  .match__score {
    padding: 10px 5px;
    gap: 5px;
  }

  .match__name {
    font-size: 14px;
    line-height: 16px;
  }

  .logo--footer .logo__icon {
    width: 57px;
  }

  .translation__text {
    padding: 5px 15px;
  }

  .video-iframe iframe {
    height: 210px;
  }
}

@media (max-width: 380px) {
  .gap--grid {
    width: auto;
    grid-template-columns: 1fr;
  }

  .clue {
    justify-content: center;
  }

  .clue__link {
    text-align: center;
  }
}
