@font-face {
  font-family: 'Coil';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local('Coil Bold'),
  local('Coil-Bold'),
  url('../fonts/coil-bold/coil-bold.woff2') format('woff2'),
  url('../fonts/coil-bold/coil-bold.woff') format('woff'),
  url('../fonts/coil-bold/coil-bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local('Inter Semi Bold'),
  local('Inter-SemiBold'),
  url('../fonts/inter-semibold/inter-semibold.woff2') format('woff2'),
  url('../fonts/inter-semibold/inter-semibold.woff') format('woff'),
  url('../fonts/inter-semibold/inter-semibold.ttf') format('ttf');
}

@font-face {
  font-family: 'Coil';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local('Coil Regular'),
  local('Coil-Regular'),
  url('../fonts/coil-regular/coil-regular.woff2') format('woff2'),
  url('../fonts/coil-regular/coil-regular.woff') format('woff'),
  url('../fonts/coil-regular/coil-regular.ttf') format('ttf');
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  margin: 0;
  padding: 0;
}

:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}

li {
  list-style-type: none;
}

:root {
  /* применение var(--color-blue); */
  /*css - переменные*/
  --white: #ffffff;
  --white-opacity: 0, 0, 0;
  --white-opacity-2: 255, 255, 255;
  --grey-opacity: 0, 66, 105;
  --grey-opacity-3: 46, 57, 77;
  --green-opacity: 0, 155, 52;
  --pink: #f9d2d2;
  --red: #d53c3c;
  --red-1: #df2020;
  --red-light: #cabcb9;
  --red-opacity: 223, 32, 32;
  --red-light-2: #e1bcb3;
  --yellow-light: #f8edcd;
  --white-2: #fcfcfc;
  --white-3: #f5f6f9;
  --black: #000000;
  --black-2: #050404;
  --black-opacity: 0, 0, 0;
  --black-opacity-2: 5, 4, 4;
  --green: #009b34;
  --green-light: #ccebd7;
  --dark-green: #007c2a;
  --green-opacity-2: 0, 155, 53;
  --grey: #d4d6da;
  --grey-2: #7e8591;
  --grey-opacity-2: 212, 214, 218;
  --grey-opacity-4: 0, 32, 51;
  --grey-light: #caccd0;
  --grey-3: #7e8984;
  --grey-3: rgba(212, 214, 218, 0.3);
  --grey-4: #7e8984;
  --milky: #e3f2e8;
  --milky-2: #e6f5eb;
  --milky-3: #f1f2f8;
  --milky-4: #caccd0;
  --blue: #e1e5f4;
  --blue-2: #002033;
  --blue-light: #d7ecff;
  --dark-blue: #283347;
  --dark-blue-2: #1c2832;
  --violet: #6b7cc6;
  --violet-opacity: 116, 127, 189;
  --violet-2: #747fbd;
  --red-2: #9c1f00;
  --sky: #b0d9ff;
  --yellow: #dba507;
  --slate: #557196;
}

a {
  text-decoration: none;
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  display: block;
  height: auto;
}

svg {
  flex-shrink: 0;
  display: flex;
}

html.fixed-safari {
  height: calc(100vh - 80px);
  overflow: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html.fixed-safari::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

body.fixed-safari {
  height: 100vh;
  position: relative;
  overflow: hidden;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body.fixed-safari::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

body.fixed {
  overflow: hidden;
  position: relative;
}

button {
  border-radius: 0;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  font-family: 'Coil', 'Arial', sans-serif;
}

.video-iframe {
  width: 100%;
}

.video-iframe iframe {
  border: none;
  width: 100%;
  height: 555px;
}

.hidden {
  display: none !important;
}

.body-container {
  max-width: 1920px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  font-family: 'Coil', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: var(--black-2);
}

.main__container {
  display: grid;
  grid-template-columns: calc(82% - 20px) 18%;
  gap: 20px;
}

.main {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  flex-grow: 1;
}

.button {
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 5px;
  justify-content: end;
  margin: 0 0 0 auto;
  z-index: 1;
  flex-shrink: 0;
}

.button--full {
  justify-content: center;
  padding: 10px 22px;
  height: 40px;
  background-color: var(--green);
  transition: 0.3s;
}

.button--full:hover {
  background-color: var(--dark-green);
  transition: 0.3s;
}

.container {
  padding: 25px 40px 75px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  position: relative;
  max-width: 1360px;
}

.container-x {
  padding: 0 40px;
  height: 100%;
  width: 100%;
}

.container-y {
  padding: 25px 0 75px;
  height: 100%;
  width: 100%;
}

.container-left {
  padding: 25px 0 75px 40px;
  height: 100%;
  width: 100%;
}

.container-row {
  padding: 0 0 0 40px;
  height: 100%;
  width: 100%;
}

.gap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.gap--60 {
  gap: 60px;
}

.gap--50 {
  gap: 50px;
}

.gap--40 {
  gap: 40px;
}

.gap--35 {
  gap: 35px;
}

.gap--30 {
  gap: 30px;
}

.gap--25 {
  gap: 25px;
}

.gap--20 {
  gap: 20px;
}

.gap--15 {
  gap: 15px;
}

.gap--0 {
  gap: 0;
}

.gap--5 {
  gap: 5px;
}

.gap--start {
  align-items: start;
  width: 100%;
}

.gap--row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.gap--row-2 {
  flex-direction: row;
  align-items: center;
  width: auto;
}

.gap--row-3 {
  flex-direction: row;
  align-items: center;
}

.saves {
  display: flex;
  justify-content: end;
  gap: 25px;
  position: relative;
  width: 100%;
}

.gap--filter {
  flex-direction: row;
  justify-content: space-between;
}

.grid {
  display: grid;
  grid-template-columns: auto 32%;
  align-items: center;
  gap: 20px;
}

.grid--sidebar {
  grid-template-columns: calc(60% - 10px) calc(40% - 10px);
  width: 100%;
  align-items: flex-start;
}

.grid--stretch {
  align-items: stretch;
}

.crumbs {
  display: flex;
  gap: 5px;
}

.crumbs__item {
  display: flex;
  gap: 5px;
  align-items: center;
}

.crumbs__item:not(:first-child):before {
  content: '';
  display: block;
  background-color: var(--violet);
  width: 2px;
  height: 2px;
  border-radius: 50%;
  flex-shrink: 0;
}

.crumbs__link {
  color: var(--grey-2);
  font-size: 12px;
  line-height: 16px;
}

.crumbs__link:hover {
  color: var(--violet);
}

.crumbs__item:last-child .crumbs__link {
  color: var(--green);
}

.title {
  color: var(--dark-blue);
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
}

.title--white {
  color: var(--white);
}

.title__green {
  color: var(--green);
}

.title--uppercase {
  text-transform: uppercase;
}

.title--center {
  text-align: center;
}

.button__link {
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}

.button__block {
  display: flex;
  background-color: var(--white);
  border-radius: 50%;
  align-items: center;
  width: 20px;
  height: 20px;
  justify-content: center;
  padding: 5px;
  transition: 0.3s;
}

.button__icon {
  display: flex;
  fill: var(--green);
  transition: 0.3s;
  margin: 0 0 0 3px;
}

.button:not(.button--full):hover .button__block {
  background-color: var(--green);
  transition: 0.3s;
}

.button:not(.button--full):hover .button__icon {
  fill: var(--white);
  transition: 0.3s;
}

.reset {
  background-color: var(--green);
  padding: 8px 10px;
  transition: 0.3s;
  max-width: 100%;
  width: 100%;
  justify-content: center;
  margin: 0 0 0 auto;
}

.reset__icon {
  fill: var(--white);
  transition: 0.3s;
}

.reset:hover .reset__icon {
  transition: 0.3s;
  fill: var(--dark-blue);
}

.search {
  position: relative;
  width: 100%;
}

.search--end {
  align-self: end;
  width: auto;
}

.search__input {
  border: 2px solid transparent;
  margin: 0;
  border-bottom: 2px solid var(--green);
  border-radius: 5px 5px 0 0;
  background-color: transparent;
  box-sizing: border-box;
  color: var(--black-2);
  font-size: 16px;
  width: 100%;
  padding: 10px 10px 10px 40px;
}

.search__input:focus,
.search__input:hover {
  border: 2px solid var(--green);
  border-radius: 5px;
}

.search__input::placeholder {
  color: var(--grey-2);
}

.search__icon {
  fill: var(--green);
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  text-align: center;
  position: absolute;
  top: 10px;
  left: 10px;
}

.filter {
  display: grid;
  gap: 20px;
  align-items: center;
}

.filter__item {
  display: flex;
}

.filter--league {
  grid-template-columns: repeat(4, calc((100% - 125px) / 4)) 45px;
}

.filter--tournaments {
  grid-template-columns: repeat(3, calc((100% - 105px) / 3)) 45px;
}

.filter--pages {
  grid-template-columns: repeat(1, 210px);
  width: 100%;
  align-items: end;
  justify-content: end;
}

.filter--players {
  grid-template-columns: calc(40% - 40px) calc(60% - 45px) 45px;
  width: 100%;
  max-width: 700px;
}

.filter--commands {
  grid-template-columns: repeat(2, calc((60% - 105px) / 2)) 40% 45px;
  width: 100%;
  max-width: 850px;
}

.filter--player {
  grid-template-columns: 1fr;
  width: 100%;
}

.filter--matches {
  grid-template-columns: repeat(2, 230px);
}

.league {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
}

.league__item {
  position: relative;
  border-radius: 6px;
  background: var(--dark-blue);
  overflow: hidden;
}

.league__block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  position: relative;
  justify-content: space-between;
  z-index: 1;
  height: 100%;
}

.league__texts {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.league__region {
  color: var(--white);
  font-size: 14px;
}

.league__name {
  color: var(--white);
  font-weight: 700;
}

.league__green .league__name {
  color: var(--green);
}

.league__background {
  display: flex;
  position: absolute;
  top: -10px;
  left: -70px;
  opacity: 0.02;
  fill: var(--white);
}

.league__play {
  color: var(--white);
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.pagination {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.pagination__arrows {
  display: flex;
  gap: 5px;
  align-items: center;
}

.pagination__arrows--right .pagination__icon {
  transform: rotate(0deg);
}

.pagination__arrows--right .pagination__link:nth-child(1) {
  order: 1;
}

.pagination__arrows--disabled .pagination__icon {
  stroke: var(--grey);
}

.pagination__arrows--disabled .pagination__link {
  cursor: auto;
}

.pagination__link {
  color: var(--dark-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination__icon {
  display: flex;
  fill: var(--dark-blue);
  transform: rotate(180deg);
  transition: 0.3s;
}

.pagination__icon:hover {
  fill: var(--green);
  transition: 0.3s;
}

.pagination__list {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.pagination__item--active .pagination__number {
  color: var(--white);
  background-color: var(--green);
}

.pagination__number {
  font-weight: 400;
  font-size: 14px;
  padding: 12px 0;
  width: 40px;
  transition: 0.3s;
  border-radius: 4px;
}

.pagination__number:hover,
.pagination__dots:hover {
  color: var(--black-2);
  background-color: var(--milky-2);
  transition: 0.3s;
}

.pagination__item--active .pagination__number:hover {
  color: var(--white);
  background-color: var(--green);
}

.pagination__dots {
  font-size: 14px;
  padding: 12px 0;
  width: 40px;
  transition: 0.3s;
  border-radius: 4px;
}

.pagination--arrows .pagination__item {
  display: none;
}

.pagination--arrows {
  gap: 40px;
}

.pagination--arrows .pagination__link {
  width: 40px;
  height: 40px;
  background-color: var(--green);
  border-radius: 100%;
  padding: 0 4px 0 0;
}

.pagination--arrows .pagination__link:hover {
  background-color: var(--dark-green);
}

.pagination--arrows .pagination__link:hover .pagination__icon {
  fill: var(--white);
}

.pagination--arrows .pagination__icon {
  fill: var(--white);
}

.pagination--arrows .pagination__arrows--right .pagination__link {
  padding: 0 0 0 4px;
}

.select,
.select-blue {
  width: 100%;
}

.select__control,
.select-blue__control {
  cursor: pointer !important;
  min-height: 40px !important;
}

.select__control {
  border: 2px solid transparent !important;
  border-radius: 5px 5px 0 0 !important;
  border-bottom: 2px solid var(--green) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.select-blue__control {
  border-color: var(--green) !important;
  background-color: var(--green) !important;
}

.select__single-value,
.select-blue__single-value {
  font-size: 16px;
}

.background .select__single-value,
.select-blue__single-value {
  color: var(--white);
  font-size: 18px;
  line-height: 20px;
}

.select-blue__single-value {
  color: var(--white) !important;
}

.select__indicator-separator,
.select-blue__indicator-separator {
  display: none !important;
}

.select__indicator,
.select-blue__indicator {
  transition: 0.3s !important;
}

.select__indicator svg {
  fill: var(--green);
}

.select-blue__indicator svg {
  fill: var(--white);
}

.select__control--menu-is-open .select__indicator,
.select-blue__control--menu-is-open .select-blue__indicator {
  transition: 0.3s;
  transform: rotate(180deg);
}

.select__menu,
.select-blue__menu {
  margin: 0 !important;
  z-index: 3 !important;
}

.select__multi-value,
.select-blue__multi-value {
  background-color: transparent !important;
}

.select__multi-value__label,
.select-blue__multi-value__label {
  color: var(--black-2) !important;
}

.select__multi-value__remove svg,
.select-blue__multi-value__remove svg {
  fill: var(--green) !important;
}

.select__menu {
  -webkit-overflow-scrolling: touch;
  box-sizing: initial !important;
  margin: 0 !important;
  padding: 0 !important;
  background: linear-gradient(0deg, rgba(var(--green), 0.2) 0%, rgba(var(--green), 0.2) 100%), var(--white);
  color: var(--black-2) !important;
  border-radius: 0 0 5px 5px !important;
}

.select__control:hover {
  border-radius: 5px !important;
  border: 2px solid var(--green) !important;
}

.select__control--menu-is-open {
  border-radius: 5px 5px 0 0 !important;
  border: 2px solid var(--green) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.select__placeholder {
  white-space: nowrap;
}

.select__option:hover {
  background-color: var(--green-light) !important;
  cursor: pointer;
}

.select__option {
  color: var(--black-2) !important;
}

.select__option--is-focused,
.select__option--is-selected {
  background-color: transparent !important;
}

.select__menu-list {
  border-radius: 0 0 5px 5px !important;
  border: 1px solid var(--green);
  border-top: none;
}

.select--registration .select__control {
  border: none !important;
  border-radius: 5px !important;
  border-bottom: none !important;
  background-color: var(--white) !important;
  box-shadow: none !important;
  min-height: 45px !important;
}

.select--form .select__control--menu-is-open {
  border-radius: 5px 5px 0 0 !important;
}

.select--form .select__menu-list {
  border: none;
}

.select--form .select__single-value,
.select-blue__single-value {
  color: var(--grey-2);
}

.select--form .select__single-value:hover,
.select-blue__single-value:hover {
  color: var(--black-2);
}

.select--form .select__control {
  border: none !important;
  border-radius: 5px !important;
  border-bottom: none !important;
  background-color: var(--white) !important;
  box-shadow: none !important;
  min-height: 45px !important;
}

.select--form.select--error .select__control {
  background-color: var(--pink) !important;
  transition: background-color 0.3s;
}

.select--form .select__control--menu-is-open {
  border-radius: 5px 5px 0 0 !important;
}

.select--form .select__menu-list {
  border: none;
}

.social {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: end;
}

.social__item {
  display: flex;
  align-items: center;
}

.social__link {
  width: 45px;
  height: 45px;
  border-radius: 3px;
  border: 1px solid rgba(var(--white-opacity-2), 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.social__icon {
  fill: var(--white);
  transition: 0.3s;
}

.social__link:hover .social__icon {
  fill: var(--green);
  transition: 0.3s;
}

.footer {
  display: flex;
  padding: 20px 40px;
  width: 100%;
  background-color: var(--dark-blue-2);
  gap: 20px;
  flex-direction: column;
  position: relative;
}

.footer__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.document__block {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  max-width: 100%;
}

.footer__list {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.footer__info {
  color: var(--white);
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 0 0 -7px;
}

.footer__info::after {
  content: '';
  display: flex;
  width: 100%;
  max-width: 240px;
  height: 1px;
  background-color: var(--white);
}

.footer__content {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0 0;
}

.footer__copyright {
  color: var(--white);
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  margin: 0 auto;
}

.footer__nav {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer__content::before {
  content: '';
  width: 100%;
  height: 1px;
  background-color: rgba(var(--grey-opacity-3), 0.3);
  position: absolute;
  left: 0;
  margin: 0 0 56px;
}

.partners {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 15px;
  align-items: center;
  width: 100%;
  justify-content: start;
}

.partners__img {
  display: flex;
}

.footer__link {
  color: rgba(var(--white-opacity-2), 0.7);
  font-size: 12px;
  line-height: 16px;
  transition: 0.3s;
}

.footer__link:hover {
  color: var(--white);
  transition: 0.3s;
}

.footer__item {
  display: flex;
}

.header {
  display: grid;
  grid-template-columns: 16% 66% 18%;
  width: 100%;
  height: 77px;
  border-bottom: 1px solid var(--grey);
  position: relative;
}

.navigation {
  display: flex;
  height: 100%;
  width: 100%;
}

.navigation__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 100%;
}

.navigation__item {
  border-left: 1px solid var(--grey);
  position: relative;
  display: flex;
  align-items: center;
}

.navigation__item--active .navigation__link {
  border-bottom: 2px solid var(--green);
  transition: 0.3s;
}

.navigation__link {
  padding: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-bottom: 2px solid var(--white);
  transition: 0.3s;
}

.navigation__icon {
  transition: transform 0.3s ease-in-out;
}

.navigation__text {
  color: var(--black-2);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.nav {
  transition: 0.3s;
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 4;
  background-color: var(--white);
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
}

.nav__item {
  width: 100%;
  max-height: 100%;
}

.nav__link {
  color: var(--black-2);
  padding: 10px;
  border-bottom: 1px solid var(--grey);
  border-right: 1px solid var(--grey);
  border-left: 1px solid var(--grey);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.nav__text {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.nav__link:hover {
  background-color: rgba(var(--green-opacity), 0.2);
}

.nav__item--active .nav__link {
  background-color: rgba(var(--green-opacity), 0.2);
}

.profile {
  background: var(--dark-blue);
  width: 100%;
  height: 100%;
}

.profile__parent {
  position: relative;
  width: 100%;
  height: 100%;
}

.profile__link {
  display: flex;
  padding: 15px 40px;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.profile__name {
  color: var(--white);
  font-size: 20px;
  line-height: 22px;
  text-transform: capitalize;
  text-align: end;
}

.profile__author {
  color: rgba(var(--white-opacity-2), 0.6);
  text-align: end;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
}

.profile__img {
  height: auto;
  border-radius: 50%;
  flex-shrink: 0;
  transition: 0.3s;
}

.profile--open .profile__img {
  border: 2px solid var(--green);
  transition: 0.3s;
}

.profile__list {
  display: none;
  flex-direction: column;
  width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  border: 1px solid var(--grey);
  background-color: var(--white);
  z-index: 1;
  transition: 0.3s;
}

.profile--open .profile__list {
  display: flex;
  transition: 0.3s;
}

.profile__item:not(:last-child) {
  border-bottom: 1px solid var(--grey);
}

.profile__unit {
  padding: 10px;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  background-color: var(--white);
  transition: 0.3s;
  display: flex;
  width: 100%;
  color: var(--black-2);
}

.profile__unit:hover {
  background-color: rgba(var(--green-opacity-2), 0.2);
  transition: 0.3s;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 100%;
}

.logo__icon {
  height: auto;
}

.logo--footer .logo__icon {
  fill: var(--white);
}

.logo--header {
  width: 100%;
}

.logo--header .logo__icon {
  fill: var(--green);
}

.buttons {
  border-radius: 6px;
  background-color: var(--dark-blue);
  display: flex;
  flex-shrink: 0;
}

.buttons--border {
  border-radius: 6px 6px 0 0;
}

.buttons.buttons--open .buttons__item--active .buttons__link {
  border-radius: 6px 6px 0 0;
}

.buttons__item {
  display: flex;
}

.buttons--border .buttons__item--active .buttons__link {
  border-radius: 6px 6px 0 0;
}

.buttons__item--active .buttons__link {
  border-radius: 6px;
  background-color: var(--green);
}

.buttons__link {
  display: flex;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-size: 18px;
  line-height: 30px;
  width: 100%;
  position: relative;
  text-align: center;
}

.buttons__link:hover:not(.buttons__item--active .buttons__link) {
  color: var(--green);
}

.tabs {
  display: flex;
  align-items: center;
  gap: 15px;
}

.tabs__item--active .tabs__button {
  background-color: var(--green);
  color: var(--white);
}

.tabs__button {
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--green);
  background-color: var(--white-2);
  color: var(--green);
  font-size: 18px;
  line-height: 30px;
}

.sorting {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 100%;
}

.sorting__text {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--white);
}

.sorting__icon {
  display: flex;
}

.table-scroll {
  overflow: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  border-radius: 5px 5px 0 0;
  border-right: 1px solid var(--grey);
  border-left: 1px solid var(--grey);
}

.table-scroll--border {
  border-radius: 5px;
  border-right: none;
  border-left: none;
}

.table-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.table-border {
  width: 100%;
  overflow: hidden;
}

.table-border--400 {
  border-radius: 5px;
  background-color: var(--white);
}

.table__auto {
  background-color: var(--white);
}

.table {
  color: var(--black-2);
  border-collapse: collapse;
  width: 100%;
}

.table-team {
  margin: 0 -1px 0 0;
  z-index: 2;
}

.table__th {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  color: var(--white);
  width: 280px;
  padding: 5px 10px;
  background-color: var(--green);
}

.table-widget__th {
  font-size: 22px;
  line-height: 26px;
  color: var(--white);
  font-weight: 700;
  background-color: var(--dark-blue);
  padding: 10px;
  text-transform: uppercase;
}

.table-widget__wrap {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  font-size: 16px;
  line-height: 20px;
}

.table-widget__wrap--end {
  justify-content: end;
}

.table-widget__value {
  color: var(--green);
}

.table__300 {
  min-width: 300px;
}

.table__127 {
  min-width: 127px;
}

.table__121 {
  min-width: 121px;
  text-wrap: nowrap;
}

.table__cell {
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  height: 50px;
  padding: 10px;
}

.table__common {
  color: var(--green);
  font-weight: 700;
}

.table__points {
  width: 100%;
}

.table__widget {
  min-width: 175px;
}

.table__big {
  font-size: 26px;
  line-height: 30px;
}

.table-team .table__cell {
  height: 61px;
}

.table-scores .table__cell {
  height: 61px;
  text-wrap: nowrap;
}

.table__team {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table__tbody .table__row {
  border-bottom: 1px solid var(--grey);
}

.table__tbody .table__row:hover {
  background-color: var(--milky-2);
}

.chess__content .table__tbody .table__row {
  border-right: none;
}

.table__green {
  color: var(--green);
  background-color: var(--milky);
  border-right: 1px solid var(--grey);
  border-left: 1px solid var(--grey);
}

.table-scores__empty {
  background-color: var(--blue);
}

.table-scores__empty .table__link {
  display: none;
}

.table__410 {
  width: 410px;
}

.table__410 .sorting {
  justify-content: start;
}

.table__wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.table__link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--black-2);
  justify-content: center;
}

.table__start .table__link {
  justify-content: flex-start;
}

.table__img {
  border-radius: 50%;
}

.table__number {
  width: 40px;
  min-width: auto;
}

.table__start {
  text-align: start;
  width: 100%;
  justify-content: start;
}

.table__start-2 {
  text-align: start;
  justify-content: start;
}

.table__row--border {
  border-right: 1px solid var(--grey);
  border-left: 1px solid var(--grey);
}

.table__row--border-2 {
  border-right: 1px solid var(--grey);
}

.table__row--border:last-child {
  border-radius: 0 0 5px 5px;
}

.chess {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--white);
}

.chess__wrapper {
  display: grid;
  grid-template-columns: 40% auto;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  border-right: 1px solid var(--grey);
  border-left: 1px solid var(--grey);
}

.table-scores {
  width: 100%;
}

.chess__content {
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.chess__content::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.chess__line {
  background-color: rgba(var(--grey-opacity), 0.06);
  height: 8px;
  border-radius: 4px;
  width: 100%;
  position: relative;
}

.chess__indicator {
  display: none;
  position: absolute;
  bottom: 0;
  height: 100%;
  border-radius: 4px;
  background: rgba(var(--grey-opacity), 0.24);
}

.match {
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
}

.match__grid {
  display: grid;
  align-items: center;
  grid-template-columns: 22% 56% 22%;
}

.match__wrap {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: calc(50% - 60px) 120px calc(50% - 60px);
}

.match__list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.match__item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.match__link {
  border-bottom: 1px solid var(--grey);
  min-height: 70px;
  border-right: 1px solid var(--grey);
  border-left: 1px solid var(--grey);
  width: 100%;
}

.match__link:hover {
  background-color: var(--milky-2);
}

.match__score {
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  gap: 7px;
}

.match__number,
.match__dot {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: var(--dark-blue);
  text-wrap: nowrap;
  text-align: center;
}

.match__win {
  color: var(--green);
}

.match__data {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
}

.match__date .match__data {
  padding: 0;
}

.match__date {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
}

.match--command .match__date {
  flex-direction: column;
}

.match__text {
  font-size: 14px;
  line-height: 18px;
  color: var(--black-2);
}

.match__icon {
  fill: var(--green);
}

.match__block {
  display: flex;
  flex-direction: column;
}

.match__time {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--black-2);
  font-size: 14px;
  line-height: 18px;
}

.match__time:before {
  content: '';
  width: 1px;
  height: 15px;
  margin: 0 0 3px;
  background-color: var(--green);
  flex-shrink: 0;
  display: flex;
}

.match--command .match__time:before {
  content: none;
}

.match__title {
  font-size: 14px;
  line-height: 20px;
  padding: 4px 10px;
  background-color: var(--green);
  color: var(--white);
}

.match__center {
  text-align: center;
}

.match__name {
  color: var(--black-2);
  font-size: 18px;
  line-height: 20px;
}

.match__team {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 25px;
  gap: 10px;
}

.match__team.match__end {
  padding: 10px 25px 10px 10px;
}

.match__end {
  justify-content: end;
  text-align: end;
}

.match__img {
  border-radius: 50%;
  height: auto;
}

.match__end .match__img {
  order: 1;
}

.stub {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  max-width: 740px;
  margin: 0 auto;
  padding: 60px 10px 75px;
}

.stub__text {
  color: var(--black-2);
  text-align: center;
  font-size: 22px;
  line-height: 28px;
  opacity: 0.6;
}

.stub--player {
  max-width: 655px;
  padding: 0;
  justify-content: center;
  margin: auto;
}

.stub--nav {
  padding: 0;
  align-items: end;
  margin: 0;
}

.stages {
  display: flex;
  align-items: center;
}

.stages__list {
  display: flex;
  align-items: stretch;
  background-color: var(--dark-blue);
  width: 100%;
}

.stages__item {
  display: flex;
  width: 100%;
  flex-grow: 1;
  align-items: center;
}

.stages__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: var(--white);
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-wrap: balance;
}

.stages__item--active .stages__button {
  border-radius: 3px;
  background-color: var(--green);
}

.stages__click {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: var(--dark-blue);
  border-radius: 100%;
  fill: var(--white);
  right: 10px;
}

.stages__icon {
  fill: var(--white);
  right: 10px;
}

.stages__click--left {
  transform: rotate(180deg);
  left: 10px;
}

.show-780 {
  display: none;
}

.tournaments {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.tournaments__item {
  position: relative;
  border-radius: 10px;
  background: var(--dark-blue);
  overflow: hidden;
  gap: 10px;
  padding: 20px;
}

.tournaments__wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  gap: 25px;
}

.tournaments__blocks {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tournaments__block {
  display: flex;
  align-items: center;
  gap: 20px;
}

.tournaments__img {
  display: flex;
  border-radius: 5px;
  height: auto;
}

.tournaments__name {
  color: var(--green);
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
}

.tournaments__texts {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 5px;
}

.tournaments__title {
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
}

.tournaments__data {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tournaments__text {
  color: var(--white);
  font-size: 14px;
  line-height: 20px;
}

.tournaments__icon {
  fill: var(--green);
}

.tournaments__container {
  display: flex;
  flex-direction: column;
}

.tournament {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.tournament__item {
  display: grid;
  grid-template-columns: 85px 1fr;
  gap: 5px;
}

.tournament__title {
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
}

.tournament__subtitle {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.tournaments__background {
  position: absolute;
  top: -30px;
  left: -60px;
  opacity: 0.02;
  fill: var(--white);
}

.tournaments__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(var(--green-opacity-2), 0.7);
  margin: 0 -15px -15px;
  padding: 10px 15px 15px;
  gap: 10px;
}

.hide-480 {
  display: none;
}

.hide-580 {
  display: none;
}

.content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.content--three {
  grid-template-columns: repeat(3, 1fr);
}

.content__item {
  width: 100%;
}

.content__link {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content__block {
  position: relative;
}

.content__icons {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 0 15px 5px 15px;
  border-radius: 0 0 5px 5px;
  background-color: var(--dark-blue);
  align-items: center;
  top: 0;
  right: 30px;
}

.content__icon {
  stroke: var(--green);
  fill: var(--green);
  transition: 0.3s;
  border-radius: 100%;
}

.content__icons--video {
  padding: 10px 15px;
}

.content__number {
  color: var(--white);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

.content__img {
  display: flex;
  width: 100%;
  height: auto;
}

.content__count {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 75px;
  height: 75px;
  bottom: -25px;
  background-color: var(--green);
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  padding: 5px;
  transition: 0.3s;
  right: 20px;
}

.content__date {
  color: var(--white);
  text-align: center;
  text-transform: capitalize;
}

.content__text {
  color: var(--black-2);
  padding: 25px 90px 30px 40px;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  border-right: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);
  border-left: 1px solid var(--grey);
  transition: 0.3s;
}

.content__link:hover .content__text {
  background-color: var(--milky-2);
  transition: 0.3s;
}

.content__link:hover .content__count {
  background-color: var(--dark-blue);
  transition: 0.3s;
}

.content__link:hover .content__icon {
  fill: var(--milky-2);
  stroke: var(--milky-2);
  transition: 0.3s;
}

.content__limiter {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.translation {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.translation__item {
  display: flex;
}

.translation__link {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.translation__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.translation__img {
  display: flex;
}

.translation__icons {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 20px;
  border-radius: 5px;
  background-color: var(--dark-blue);
  align-items: center;
  z-index: 1;
}

.translation__icon {
  fill: var(--green);
  stroke: var(--green);
  transition: 0.3s;
  border-radius: 100%;
}

.translation__link:hover .translation__icon {
  fill: var(--milky-2);
  stroke: var(--milky-2);
  transition: 0.3s;
}

.translation__text {
  color: var(--green);
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  border-bottom: 1px solid var(--grey);
  border-left: 1px solid var(--grey);
  border-right: 1px solid var(--grey);
  padding: 36px 40px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  width: 100%;
}

.translation__link:hover .translation__text {
  background-color: var(--milky-2);
  transition: 0.3s;
}

.author {
  display: flex;
  align-items: center;
  align-self: start;
  gap: 10px;
}

.author__block {
  display: flex;
  align-items: center;
  align-self: start;
  gap: 10px;
}

.author__name {
  color: var(--dark-blue);
  text-transform: capitalize;
}

.author__green {
  color: var(--green);
}

.author__info {
  color: var(--dark-blue);
  display: flex;
  gap: 10px;
  align-items: center;
}

.author__info:before {
  content: '';
  display: flex;
  width: 1px;
  height: 15px;
  background-color: var(--green);
}

.container--850 {
  max-width: 850px;
  align-items: center;
}

.container--1080 {
  max-width: 1080px;
}

.images {
  display: flex;
  align-items: center;
  align-self: self-start;
  justify-content: center;
  position: relative;
  max-width: 100%;
  width: 100%;
}

.images__picture {
  display: flex;
}

.images__block {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--dark-blue);
  z-index: 1;
}

.images__icon {
  fill: var(--green);
  stroke: var(--green);
  transition: 0.3s;
  border-radius: 100%;
  height: auto;
}

.images:hover .images__icon {
  fill: var(--milky-2);
  stroke: var(--milky-2);
  transition: 0.3s;
}

.sidebar {
  display: flex;
  flex-direction: column;
  padding: 0 0 75px;
}

.sidebar__list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.sidebar__item {
  display: flex;
}

.sidebar__link {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sidebar__content {
  position: relative;
  display: flex;
}

.sidebar__img {
  display: flex;
  width: 100%;
}

.sidebar__block {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 7px 12px;
  border-radius: 0 0 5px 5px;
  background-color: var(--dark-blue);
  z-index: 1;
  right: 40px;
}

.sidebar__icon {
  fill: var(--green);
  stroke: var(--green);
  transition: 0.3s;
  border-radius: 100%;
  height: auto;
  width: 25px;
}

.sidebar__link:hover .sidebar__icon {
  fill: var(--milky-2);
  stroke: var(--milky-2);
  transition: 0.3s;
}

.sidebar__text {
  display: flex;
  padding: 15px 40px 20px 20px;
  align-items: center;
  color: var(--black-2);
  font-size: 16px;
  line-height: 22px;
  transition: 0.3s;
  border-right: 1px solid var(--grey);
  border-left: 1px solid var(--grey);
}

.sidebar__item:last-child .sidebar__text {
  border-bottom: 1px solid var(--grey);
}

.sidebar__count {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -10px;
  background-color: var(--green);
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: 0.3s;
  right: 40px;
  flex-shrink: 1;
}

.sidebar__date {
  color: var(--white);
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
}

.next {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  background-color: var(--green);
  align-items: center;
  justify-content: center;
}

.next__text {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
}

.sidebar__link:hover .sidebar__icon {
  fill: var(--milky-2);
  stroke: var(--milky-2);
  transition: 0.3s;
}

.sidebar__link:hover .sidebar__text {
  background-color: var(--milky-2);
  transition: 0.3s;
}

.sidebar__link:hover .sidebar__count {
  color: var(--white);
  transition: 0.3s;
  background-color: var(--dark-blue);
}

.hashtag {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-wrap: wrap;
}

.hashtag__link {
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: var(--milky-3);
  color: var(--dark-blue);
  font-size: 16px;
  line-height: 20px;
}

.hashtag__link:hover {
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: var(--violet-2);
}

.album {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.album__item {
  display: flex;
}

.album__link {
  display: block;
}

.album__img {
  width: 100%;
  display: flex;
  height: auto;
}

.arrows {
  display: flex;
  align-items: center;
  gap: 40px;
  margin: 0 auto;
}

.arrows__button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: var(--green);
}

.arrows__button {
  padding: 0 0 0 4px;
}

.arrows__button:hover {
  background-color: var(--dark-green);
}

.arrows__icon {
  display: flex;
  fill: var(--white);
}

.arrows__button--left {
  transform: rotate(180deg);
}

.documents {
  display: flex;
  padding: 55px 45px;
  flex-direction: column;
  border: 1px solid var(--grey);
  background-color: var(--white-3);
  border-radius: 10px;
  gap: 30px;
}

.documents--end {
  align-items: end;
  padding: 30px 15px;
}

.documents--end .documents__texts {
  order: -1;
  align-items: end;
}

.documents--end .documents__text {
  text-align: right;
}

.documents__list {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.documents__link {
  display: flex;
  gap: 10px;
}

.documents__texts {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.documents__text {
  color: var(--black-2);
}

.documents__down {
  color: var(--green);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}

.documents__icon {
  height: 100%;
}

.documents__black {
  display: flex;
  transition: 0.3s ease;
}

.documents__green {
  display: none;
  transition: 0.3s ease;
}

.documents__link:hover .documents__green {
  display: flex;
  transition: 0.3s ease;
}

.documents__link:hover .documents__black {
  display: none;
  transition: 0.3s ease;
}

.contact {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--grey);
  background-color: var(--white-3);
  overflow: hidden;
}

.contact__block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px 30px;
}

.contact__texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.contact__subtitle {
  color: var(--dark-blue);
  line-height: 26px;
}

.contact__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact__item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contact__data {
  color: var(--green);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

.contact__text {
  color: var(--black-2);
}

.contact__map {
  display: flex;
  width: 100%;
  height: 330px;
  border-radius: 10px 10px 0 0;
  object-position: center;
  object-fit: cover;
}

.sidebar-banner {
  display: flex;
  flex-direction: column;
  padding: 0 0 75px;
}

.banner {
  display: flex;
  flex-direction: column;
}

.banner__img {
  display: flex;
  width: 100%;
}

.tourney {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tourney__wrap {
  display: flex;
  flex-direction: column;
  background-color: var(--green);
  padding: 15px 40px 15px 15px;
  border-radius: 6px 6px 0 0;
  gap: 30px;
  margin: -5px 0 0 0;
  position: relative;
  align-items: center;
}

.tourney__background {
  position: absolute;
  opacity: 0.07;
  left: -185px;
  top: 12px;
  fill: var(--white);
}

.tourney__img {
  display: flex;
  width: 100%;
}

.tourney__block {
  display: flex;
  gap: 15px;
  z-index: 1;
  width: 100%;
}

.tourney__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.tourney__title {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.tourney__subtitle {
  color: rgba(var(--white-opacity-2), 0.7);
  font-size: 14px;
  line-height: 20px;
}

.tourney__logo {
  display: flex;
  margin: -40px 0 0;
  border-radius: 5px;
  height: 70px;
}

.tourney__list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 1;
  width: 100%;
}

.tourney__item {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
}

.tourney__text {
  max-width: 80px;
  width: 100%;
}

.tourney__name {
  font-weight: 700;
}

.accordion {
  display: grid;
  grid-auto-columns: 1fr;
  gap: 20px;
}

.accordion__item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.accordion__block {
  border-radius: 5px;
  background-color: var(--dark-blue);
  display: flex;
  gap: 25px;
  padding: 10px 25px;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
  width: 100%;
  min-height: 50px;
}

.accordion__block:hover {
  cursor: pointer;
}

.accordion__title {
  color: var(--white);
}

.accordion__icon {
  fill: var(--white);
}

.accordion__border {
  width: 30px;
  height: 30px;
  background-color: var(--green);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  transition: 0.3s;
  flex-shrink: 0;
  padding: 0 0 0 3px;
}

.accordion__wrap {
  display: none;
  flex-direction: column;
  border-radius: 0 0 5px 5px;
  background-color: rgba(var(--violet-opacity), 0.1);
  padding: 20px;
  gap: 15px;
  transition: 0.3s;
}

.accordion__item--open .accordion__block {
  border-radius: 5px 5px 0 0;
  transition: 0.3s;
}

.accordion__item--open .accordion__wrap {
  display: flex;
  transition: 0.3s;
}

.accordion__item--open .accordion__border {
  transform: rotate(-90deg);
  transition: 0.3s;
}

.legal {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.legal__text {
  font-weight: 700;
  color: var(--green);
}

.legal__blue {
  color: var(--dark-blue);
  font-weight: 400;
}

.amount {
  display: flex;
  gap: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 34px;
  text-transform: uppercase;
  align-items: center;
  flex-shrink: 0;
}

.amount__text {
  color: var(--dark-blue);
}

.amount__number {
  color: var(--green);
}

.background {
  display: flex;
  flex-direction: column;
  padding: 50px 30px 70px;
  border-radius: 15px;
  background-color: var(--dark-blue);
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 60px;
}

.background--command {
  padding: 30px 20px 40px;
}

.background__wrapper {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background__logo {
  opacity: 0.03;
  position: absolute;
  left: -258px;
  fill: var(--white);
}

.players {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  z-index: 1;
}

.players__block {
  position: relative;
}

.players__img {
  display: flex;
  border-radius: 100%;
  height: auto;
}

.efficiency {
  color: var(--green);
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border: 1.5px solid var(--green);
  border-radius: 100%;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 15px;
}

.efficiency--players {
  right: 0;
}

.efficiency__number {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.efficiency__text {
  font-size: 8px;
  line-height: 10px;
}

.players__name {
  color: var(--white);
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  text-transform: capitalize;
  display: inline-flex;
  flex-direction: column;
}

.players__locus {
  color: rgba(var(--white-opacity-2), 0.7);
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.players__age {
  color: var(--white);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.blocks {
  border-radius: 0 15px 15px 15px;
  background-color: rgba(var(--grey-opacity-2), 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.blocks__background {
  opacity: 0.03;
  position: absolute;
  left: -254px;
  fill: var(--dark-blue);
}

.blocks__container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.blocks__container--start {
  align-items: start;
  justify-content: start;
}

.grid-profile {
  display: grid;
  grid-template-columns: calc(30% - 10px) calc(70% - 10px);
  gap: 20px;
}

.meet {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.meet__grid {
  background-color: var(--white);
  border-left: 1px solid var(--grey);
  border-right: 1px solid var(--grey);
  border-top: 1px solid var(--grey);
  border-radius: 0 0 5px 5px;
}

.meet__grid:last-child {
  border-bottom: 1px solid var(--grey);
}

.meet__grid--green {
  background-color: var(--green);
  border: none;
  border-radius: 0;
}

.meet__grid--green .meet__parent {
  padding: 5px 10px;
}

.meet__parent {
  display: grid;
  grid-template-columns: calc(40% - 10px) calc(20% - 5px) calc(20% - 5px) calc(20% - 10px);
  gap: 10px;
  align-items: center;
}

.meet__title {
  color: var(--white);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.meet__list {
  display: flex;
  flex-direction: column;
}

.meet__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  height: 100%;
  padding: 10px;
}

.meet__players {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  align-items: center;
}

.meet__link {
  display: flex;
  align-items: center;
  gap: 5px;
}

.meet__img {
  border-radius: 100%;
}

.meet__container .meet__text {
  text-align: start;
}

.meet__text {
  color: var(--black-2);
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  display: flex;
  gap: 5px;
}

.meet__slash {
  display: none;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
}

.meet__content {
  height: 100%;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: var(--blue);
}

.meet__scores {
  display: flex;
  gap: 30px;
  align-items: center;
  flex-direction: column;
}

.meet__number {
  color: var(--black-2);
  text-align: center;
  font-size: 16px;
  line-height: 18px;
}

.meet__number--win {
  color: var(--green);
  font-weight: 700;
}

.meet__block {
  display: none;
}

.command {
  display: flex;
  flex-direction: column;
  gap: 35px;
  z-index: 1;
  width: 100%;
}

.command__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 55px;
}

.command__title {
  color: var(--white);
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  text-transform: capitalize;
}

.command__img {
  border-radius: 100%;
}

.command__list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.command__item {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 20px;
  align-items: center;
}

.command__name {
  color: var(--white);
  font-size: 18px;
  line-height: 22px;
}

.command__text {
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
}

.command__link {
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-decoration: underline;
}

.command__block {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 20px;
}

.command__content {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.command__texts {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.command__icon {
  height: auto;
}

.command__number {
  color: var(--white);
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
}

.command__subtitle {
  color: var(--green);
  font-size: 10px;
  line-height: 12px;
  height: 100%;
  display: flex;
  align-items: center;
}

.player-scroll {
  overflow: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  height: 515px;
  padding: 0 20px 0 0;
}

.player {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.player__link {
  display: flex;
  align-items: center;
  gap: 20px;
}

.player__content {
  position: relative;
  flex-shrink: 0;
}

.player__img {
  border-radius: 100%;
  height: auto;
}

.player__full {
  color: var(--black-2);
  font-size: 28px;
  line-height: 32px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.os-scrollbar .os-scrollbar-track {
  border-radius: 4px;
  width: 8px;
  background: rgba(var(--grey-opacity), 0.06);
}

.os-scrollbar .os-scrollbar-handle {
  width: 8px;
  border-radius: 4px;
  background: rgba(var(--grey-opacity), 0.24);
}

.os-scrollbar .os-scrollbar-handle:hover {
  border-radius: 4px;
  background: rgba(var(--grey-opacity), 0.24);
}

.os-scrollbar .os-scrollbar-track:hover {
  border-radius: 4px;
  background: rgba(var(--grey-opacity), 0.06);
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  font-family: 'Coil', 'Arial', sans-serif;
  font-weight: 400;
  background-color: rgba(var(--violet-opacity), 0.9);
  backdrop-filter: blur(8px);
}

.modal__form {
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  height: 100%;
}

.modal__text {
  color: var(--white);
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  margin: 0 -25px;
  text-wrap: balance;
}

.modal__scroll {
  overflow: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
  width: 100%;
  margin: 0 0 85px;
  border-radius: 15px 0 15px 15px;
}

.modal__scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  top: 75px;
  height: 100%;
  max-width: 600px;
  padding: 0 10px;
}

.modal--verification .modal__container {
  top: auto;
  height: auto;
}

.modal--verification .modal__scroll {
  margin: 0;
}

.modal__link {
  color: var(--green);
  text-decoration: underline;
}

.modal__button {
  color: var(--white);
  text-align: center;
  font-size: 22px;
  line-height: 35px;
  background-color: var(--green);
  border-radius: 5px;
  padding: 8px 15px;
  width: 100%;
  transition: 0.3s;
}

.modal__button--uppercase {
  text-transform: uppercase;
}

.modal__button:disabled {
  color: var(--grey-3);
  background-color: var(--milky-4);
  transition: 0.3s;
}

.modal__button:active {
  color: var(--green);
  background-color: rgba(var(--green-opacity-2), 0.24);
  transition: 0.3s;
}

.modal__button:hover {
  background-color: var(--dark-green);
  transition: 0.3s;
}

.modal__background {
  border-radius: 15px 0 15px 15px;
  background: var(--dark-blue);
  padding: 40px 75px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.modal__logo {
  position: absolute;
  opacity: 0.03;
}

.modal__list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.modal__item {
  display: flex;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  position: relative;
}

.form__text {
  color: var(--white);
  font-size: 14px;
  line-height: 16px;
  align-self: start;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  text-wrap: nowrap;
}

.form__input {
  color: var(--black-2);
  font-size: 16px;
  line-height: 25px;
  border: none;
  background-color: var(--white);
  border-radius: 5px;
  padding: 10px 15px;
  width: 100%;
  transition: 0.3s;
}

.form__input::placeholder {
  color: var(--grey-2);
  font-size: 16px;
  line-height: 24px;
}

.form__input:disabled {
  background-color: var(--grey-light);
}

.form__input:invalid {
  background: linear-gradient(
    0deg,
    rgba(var(--red-opacity), 0.2) 0%,
    rgba(var(--red-opacity), 0.2) 100%
  ),
  var(--white);
}

.form__input:invalid::placeholder {
  color: var(--black-2);
}

.form__input:hover::placeholder {
  color: var(--blue-2);
}

.form--password .form__input {
  padding: 10px 45px 10px 15px;
}

.form__input--error {
  background-color: var(--pink);
  transition: 0.3s;
}

.form__control {
  position: absolute;
  right: 15px;
  bottom: 10px;
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(../images/show-icon.svg) 0 0 no-repeat;
  transition: 0.3s;
}

.form__control--view {
  background: url(../images/hide-icon.svg) 0 0 no-repeat;
  transition: 0.3s;
}

.form__password {
  position: relative;
}

.form__help {
  color: var(--red-1);
  font-size: 12px;
  line-height: 12px;
  display: flex;
  justify-content: center;
  text-wrap: wrap;
  align-self: start;
}

.form__help--center {
  text-align: center;
}

.modal__close {
  position: absolute;
  right: -85px;
  top: 0;
  height: auto;
}

.modal__icon {
  fill: var(--green);
  transition: 0.3s;
}

.modal__icon:hover {
  fill: var(--dark-green);
  transition: 0.3s;
}

.modal__block {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.check {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.check__input {
  display: none;
}

.check__indicator {
  flex-shrink: 0;
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 5px;
  border: 2px solid var(--green);
}

.check__input:checked + .check__indicator {
  background-image: url('../images/check.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--green);
}

.check__text {
  color: var(--white);
  font-size: 14px;
  line-height: 20px;
}

.modal__recovery {
  color: var(--green);
  text-align: right;
  font-size: 14px;
  line-height: 16px;
}

.modal__reactivation {
  color: var(--green);
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  margin: 5px 0 0;
}

.modal__column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin: -25px 0 0;
}

.modal__errors {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: var(--red-1);
  min-height: 16px;
}

.modal__recaptcha {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal--close {
  display: none;
}

.switch {
  display: flex;
  align-items: center;
  border-radius: 6px 6px 0 0;
  background: var(--dark-blue);
  align-self: end;
}

.switch__item--active .switch__button {
  color: var(--white);
  border-radius: 6px 6px 0 0;
  background-color: var(--green);
  transition: 0.3s;
}

.switch__item--active .switch__button:hover {
  background-color: var(--dark-green);
  transition: 0.3s;
  color: var(--white);
}

.switch__button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 30px;
  color: var(--white);
  padding: 10px 15px;
  border-radius: 6px 6px 0 0;
  background-color: transparent;
}

.switch__button:hover {
  color: var(--green);
}

.background-form {
  padding: 50px 20px;
  border-radius: 15px;
  background-color: var(--dark-blue);
  width: 100%;
  display: flex;
  align-items: center;
  gap: 55px;
}

.background-form--profile {
  padding: 50px 20px 50px 55px;
}

.compound {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
}

.compound__item {
  display: flex;
  align-items: center;
  gap: 55px;
  position: relative;
}

.avatar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  flex-shrink: 0;
}

.compound__number {
  position: absolute;
  left: -35px;
  top: 0;
  color: var(--white);
  font-size: 24px;
  line-height: 34px;
}

.avatar__img {
  border-radius: 100%;
}

.avatar__texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.avatar__text {
  color: var(--white);
  text-align: center;
  font-size: 10px;
  line-height: 12px;
}

.avatar__error {
  color: var(--red-1);
  font-size: 10px;
  line-height: 12px;
  width: 160px;
  white-space: normal;
  text-align: center;
}

.avatar__title {
  color: var(--green);
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.fields__item {
  display: grid;
  align-items: end;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  column-gap: 20px;
  row-gap: 15px;
}

.fields__item--3 {
  grid-template-columns: repeat(3, 1fr);
}

.proffer {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.proffer__link {
  display: flex;
  gap: 5px;
  align-items: center;
}

.proffer__text {
  color: var(--white);
  font-size: 16px;
  line-height: 24px;
  align-self: end;
}

.proffer__icon {
  fill: transparent;
}

.proffer__link:hover .proffer__icon {
  fill: var(--white);
}

.save {
  background-color: var(--green);
  padding: 8px 15px;
  color: var(--white);
  border-radius: 5px;
  line-height: 30px;
  transition: 0.3s;
}

.save--pay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 190px;
}

.save:hover {
  background-color: var(--dark-green);
  transition: 0.3s;
}

.save--green {
  background-color: rgba(var(--green-opacity-2), 0.24);
  color: var(--green);
  transition: 0.3s;
}

.save--green:hover {
  background-color: var(--green);
  color: var(--white);
  transition: 0.3s;
}

.save--disabled {
  background-color: var(--grey-light);
  color: var(--grey-4);
  margin: 0 0 0 auto;
  cursor: auto;
}

.save--disabled:hover {
  background-color: var(--grey-light);
  color: var(--grey-4);
}

.save--pay .save--disabled {
  background-color: var(--grey-light);
  color: var(--grey-4);
  cursor: auto;
  margin: 0;
}

.clue {
  display: flex;
  gap: 5px;
  align-items: center;
}

.clue--white .clue__text {
  color: var(--white);
}

.clue__icon {
  fill: var(--red-2);
}

.clue--draft .clue__icon {
  fill: var(--slate);
}

.clue__text {
  color: var(--dark-blue);
  font-weight: 700;
  line-height: 35px;
  text-transform: uppercase;
}

.show-1080 {
  display: none;
}

.reason {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  background-color: var(--red-light);
  padding: 8px 10px;
}

.reason__title {
  color: var(--red-2);
  font-weight: 700;
  line-height: 25px;
  text-transform: uppercase;
}

.reason__subtitle {
  color: var(--dark-blue);
}

.matches {
  position: relative;
}

.matches__wrapper {
  position: relative;
  background-color: var(--dark-blue);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.matches__container {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 15px 30px 30px;
}

.matches__background {
  position: absolute;
  opacity: 0.03;
  fill: var(--white);
}

.matches__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.matches__block {
  display: flex;
  align-items: center;
  gap: 5px;
}

.share__border {
  padding: 4px;
  background-color: var(--white);
  border-radius: 100%;
  transition: 0.3s;
}

.matches__icon {
  fill: var(--green);
  transition: 0.3s;
}

.share__logo {
  fill: var(--green);
  transition: 0.3s;
  height: auto;
}

.share__link:hover .share__logo {
  fill: var(--white);
  transition: 0.3s;
}

.share__link:hover .share__border {
  background-color: var(--green);
  transition: 0.3s;
}

.share__socials {
  display: none;
  transition: 0.3s;
  gap: 10px;
  right: -160px;
  align-items: center;
  padding: 10px 17px;
  background-color: var(--white);
  position: absolute;
  border-radius: 0 8px 8px 0;
  box-shadow: 0 8px 24px 0 rgba(var(--grey-opacity-4), 0.12),
  0 4px 4px 0 rgba(var(--grey-opacity-4), 0.04);
}

.share__line {
  width: 5px;
  height: 100%;
  position: absolute;
  border-radius: 8px 0 0 8px;
  background-color: var(--green);
  display: flex;
  left: -5px;
}

.share__social {
  width: 30px;
  height: 30px;
  background-color: var(--dark-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.share__icon {
  fill: var(--white);
  height: auto;
  transition: 0.3s;
}

.share__social:hover .share__icon {
  fill: var(--green);
  transition: 0.3s;
}

.share--open .share__socials {
  display: flex;
  transition: 0.3s;
}

.matches__data {
  color: var(--white);
  font-size: 16px;
  line-height: 22px;
}

.matches__wrap {
  display: grid;
  grid-template-columns: calc(40% - 15px) calc(20% - 10px) calc(40% - 15px);
  gap: 20px;
}

.matches__player {
  display: flex;
  align-items: center;
  gap: 20px;
}

.matches__player--right {
  justify-content: flex-end;
}

.matches__img {
  border-radius: 100%;
  height: auto;
}

.matches__name {
  color: var(--white);
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  text-transform: capitalize;
  text-align: start;
}

.matches__player--right .matches__name {
  text-align: end;
  order: -1;
}

.matches__scores {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.matches__number {
  color: var(--white);
  text-align: center;
  font-size: 96px;
  font-weight: 700;
  line-height: 116px;
  min-width: 116px;
}

.matches__colon {
  color: var(--white);
  text-align: center;
  font-size: 46px;
  font-weight: 700;
  line-height: 50px;
}

.matches__subtitle {
  color: var(--white);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}

.share {
  position: absolute;
  top: 15px;
  right: 30px;
  z-index: 2;
  display: flex;
  gap: 10px;
  align-items: center;
}

.share__link {
  display: flex;
  align-items: center;
  gap: 5px;
}

.share__text {
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}

.game {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -15px;
}

.game__item:last-child {
  border-bottom: 1px solid var(--grey);
}

.game__head {
  background-color: var(--green);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}

.game__title {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

.game__content {
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: var(--white);
  font-size: 16px;
  line-height: 18px;
  flex-wrap: wrap;
}

.game__subtitle {
  display: flex;
  align-items: center;
  gap: 5px;
}

.game__subtitle:not(:last-child):after {
  content: '';
  width: 1px;
  height: 12px;
  background-color: var(--white);
  display: flex;
}

.game__grid {
  display: grid;
  border-left: 1px solid var(--grey);
  border-right: 1px solid var(--grey);
  grid-template-columns: 1fr 120px 1fr;
}

.game__container {
  padding: 15px 15px 15px 30px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.game__container--right {
  padding: 15px 30px 15px 15px;
}

.game__container--right .game__link {
  justify-content: end;
}

.game__players {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.game__link {
  display: flex;
  align-items: center;
  gap: 10px;
}

.game__img {
  border-radius: 100%;
}

.game__text {
  color: var(--black-2);
}

.game__container--right .game__text {
  text-align: end;
  order: -1;
}

.game__container--right .game__win {
  order: -1;
}

.game__point {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: var(--violet);
  flex-shrink: 0;
}

.game__win {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.game__victory {
  color: var(--white);
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  background-color: var(--violet);
  box-shadow: 0 4px 4px 0 rgba(var(--black-opacity), 0.25);
  padding: 5px 10px;
  position: absolute;
  right: -74px;
  display: none;
}

.game__container--right .game__victory {
  left: -74px;
  right: auto;
}

.game__win:hover .game__victory {
  display: block;
}

.game__block {
  background-color: var(--blue);
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 12px 10px;
  align-items: center;
  justify-content: center;
}

.game__scores {
  display: flex;
  align-items: center;
  color: var(--black-2);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
}

.game__number {
  width: 45px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.game__points {
  width: 10px;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.game__number--win {
  color: var(--green);
}

.personal {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  max-width: 925px;
  margin: 0 auto;
}

.personal__text {
  color: var(--white);
  text-align: center;
  font-size: 22px;
  line-height: 32px;
}

.personal__link {
  display: flex;
  padding: 8px 15px;
  border-radius: 5px;
  color: var(--white);
  line-height: 30px;
  background-color: var(--green);
  width: 100%;
  max-width: 220px;
  align-items: center;
  transition: 0.3s;
  justify-content: center;
}

.personal__link:hover {
  background-color: var(--dark-green);
  transition: 0.3s;
}

.payment {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
}

.payment__item {
  display: grid;
  grid-template-columns: 1fr 33%;
  align-items: center;
  gap: 60px;
}

.payment__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: end;
}

.payment__title {
  color: var(--white);
  font-size: 24px;
  line-height: 35px;
}

.payment__pay {
  display: flex;
  align-items: end;
  gap: 60px;
  justify-content: end;
}

.payment__price {
  color: var(--green);
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  flex-shrink: 0;
}

.mistake {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  max-width: 920px;
  margin: 0 auto;
  padding: 100px 30px;
  text-align: center;
}

.mistake__number {
  color: var(--green);
  font-size: 144px;
  font-weight: 700;
  line-height: 175px;
}

.mistake__title {
  color: var(--dark-blue);
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.mistake__subtitle {
  line-height: 26px;
}

.mistake__link {
  color: var(--violet);
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verification {
  color: var(--white);
  text-align: center;
  font-size: 22px;
  line-height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

input[type='date'] {
  font-family: Arial, sans-serif;
}

.widget__link {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 35px 20px 20px;
  border: 1px solid var(--grey);
  justify-content: space-between;
}

.widget__item:not(:first-child) .widget__link {
  border-left: none;
}

.widget__teams {
  display: flex;
  gap: 5px;
  align-items: center;
  height: 100%;
}

.widget__team {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-self: baseline;
  gap: 5px;
  height: 100%;
}

.widget__logo {
  border-radius: 100%;
}

.widget__name {
  color: var(--black-2);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin: auto;
}

.widget__scores {
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
}

.widget__score {
  color: var(--dark-blue);
}

.widget__score--win {
  color: var(--green);
}

.widget__infos {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: var(--green);
  text-align: center;
}

.promo {
  display: flex;
}

.promo__picture {
  display: flex;
  width: 100%;
}

.promo__img {
  width: 100%;
}

.avatar__title {
  cursor: pointer;
}

.widget .tns-slider {
  display: flex;
  align-items: stretch;
}
