.playoff__top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;
  position: relative;
}

.top.top--dark {
  display: none;
}

.playoff__arrow {
  cursor: pointer;
  display: none;
  left: 10px;
  align-items: center;
  justify-content: center;
  background-color: var(--dark-blue);
  border-radius: 100%;
  position: absolute;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  z-index: 2;
}

.playoff__arrow.playoff__arrow--right {
  right: 10px;
  left: auto;
}

.playoff__arrow-icon {
  margin: 0 2px 0 0;
  fill: var(--white);
}

.playoff__arrow--right .playoff__arrow-icon {
  margin: 0 0 0 2px;
}

.playoff__step {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30px;
  background-color: var(--dark-blue);
}

.playoff__step-item {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.playoff__step-title {
  position: absolute;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--white);
  white-space: nowrap;
  z-index: 2;
}

.playoff__active {
  background-color: var(--green);
  display: flex;
}

.playoff__step-half {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.playoff__step-container {
  border-radius: 3px;
  z-index: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.playoff__step-container:not(:first-child) {
  display: none;
}

/*.playoff__step-item--32 .playoff__step-container {
  justify-content: space-between;
}*/

/*.playoff__step-item--8 .playoff__step-container {
  justify-content: center;
}*/

/*.playoff__step-item--8 .playoff__step-line {
  margin: 4px 0;
}*/

.playoff__step-item--8 .playoff__step-line:nth-child(4) {
  margin-bottom: 20px;
}

.playoff__step-item--final .playoff__step-container {
  align-items: center;
  justify-content: center;
}

.playoff__step-icon {
  fill: rgba(var(--white-opacity), 0.8);
}

.playoff__step-line {
  display: none;
}

.playoff__body {
  display: flex;
  justify-content: center;
  margin: 20px 0 0 0;
}

.playoff__body:nth-child(2) {
  margin: 50px 0 0 0;
}

.playoff__grid {
  display: flex;
  flex-direction: column;
}

.playoff__grid--up {
  margin: 0 0 60px;
}

.playoff__column--middle,
.playoff__column--last,
.playoff__column--grand {
  flex-grow: 1;
  max-width: 560px;
}

.playoff__container {
  display: flex;
  height: 120px;
  margin: 0 0 20px 0;
}

.playoff__item {
  border: 1px solid var(--grey);
  background-color: var(--white);
  border-radius: 10px;
  width: 340px;
  height: 120px;
  display: flex;
  position: relative;
  z-index: 1;
}

.playoff__teams {
  flex-grow: 1;
  height: 100%;
}

.playoff__team {
  display: flex;
  align-items: center;
  height: 50%;
  padding: 5px 0 5px 15px;
  position: relative;
  z-index: 1;
  gap: 5px;
  overflow: hidden;
  border-radius: 0 0 0 8px;
}

.playoff__team:first-child {
  border-radius: 8px 0 0;
}

.playoff__team:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  z-index: -1;
  transition: width 0.3s;
  background-color: var(--green);
}

.playoff__team--highlighted:after {
  width: 100%;
}

.playoff__team--winner:not(.playoff__team--highlighted):after {
  background: var(--green);
  width: 4px;
}

.playoff__picture {
  flex-shrink: 0;
}

.playoff__img {
  display: block;
  border-radius: 100%;
  width: 26px;
  height: 26px;
}

.playoff__team-name {
  font-size: 16px;
  line-height: 20px;
  color: var(--black-2);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.playoff__team--winner .playoff__team-name {
  font-weight: 700;
}

.playoff__team--highlighted .playoff__team-name {
  color: var(--white);
}

.playoff__info {
  flex-shrink: 0;
  text-align: center;
  color: rgba(var(--white-opacity), 0.5);
  font-size: 12px;
  line-height: 16px;
  height: 100%;
  padding: 0 5px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.playoff__text {
  color: rgba(var(--white-opacity), 0.5);
}

.playoff__match {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px 10px;
  min-width: 30px;
}

.playoff__score {
  font-size: 16px;
  line-height: 20px;
  color: var(--black-2);
}

.playoff__score--winner {
  color: var(--green);
}

.playoff__score-additional {
  text-transform: uppercase;
  color: rgba(var(--white-opacity), 0.7);
}

.playoff__score-penalty {
  font-size: 10px;
  line-height: 14px;
  color: var(--blue);
}

.playoff__future,
.playoff__text {
  padding: 0 12px;
  width: 140px;
}

.playoff__future {
  display: flex;
  margin: auto 0;
  color: rgba(var(--black-opacity-2), 0.5);
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  gap: 7px;
  justify-content: center;
}

/* Расстояния между */

.playoff__column--main .playoff__container:nth-child(even) {
  margin: 0 0 60px 0;
}

.playoff__column--middle {
  padding: 70px 0 0 0;
}

.playoff__grid--grand .playoff__column--middle {
  padding: 0;
}

.playoff__column--middle .playoff__container {
  margin: 0 0 200px 0;
}

.playoff__column--last {
  padding: 230px 0 0 0;
}

.playoff__column--last.playoff__column--empty {
  padding: 60px 0 0 0;
}

.playoff__column--last .playoff__container {
  margin: 0 0 520px 0;
}

.playoff__column--grand .playoff__line {
  height: 588px;
  margin-top: -272px;
}

.playoff__container:last-child {
  margin: 0 !important;
}

/* Соединители */

.playoff__column--main .playoff__line {
  display: none;
}

.playoff__line {
  height: 120px;
  flex-grow: 1;
  display: flex;
  position: relative;
  top: 50%;
  margin-top: -60px;
}

.playoff__column--empty .playoff__line {
  flex-direction: column;
}

.playoff__column--last .playoff__line {
  height: 252px;
  margin-top: -126px;
}

.playoff__column--empty.playoff__column--last {
  padding: 185px 0 0 0;
}

.playoff__column--empty.playoff__column--last .playoff__line {
  flex-direction: column;
}

.playoff__line-part {
  width: 50%;
  height: 100%;
  position: relative;
}

.playoff__line-part__up {
  width: 100%;
  height: 50%;
  border-right: 1px solid var(--grey);
  border-top: 1px solid var(--grey);
}

.playoff__line-part--single {
  width: 100%;
}

.playoff__column--empty .playoff__line-part__up {
  border-right: none;
}

.playoff__column--empty .playoff__line-part__down {
  border-right: none;
}

.playoff__line-part__down {
  width: 100%;
  height: 50%;
  border-right: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);
}

.playoff__line-part--highlighted-upper .playoff__line-part__up,
.playoff__line-part--highlighted-lower .playoff__line-part__down {
  border-color: var(--grey);
}

.playoff__line-part--highlighted-upper .playoff__line-part:last-child:after,
.playoff__line-part--highlighted-lower .playoff__line-part:last-child:after {
  background-color: var(--grey);
}

.playoff__line-part:last-child:after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: var(--grey);
  position: absolute;
  top: 50%;
  left: 0;
}

/* Финальные матчи */

.playoff__container--final .playoff__team--winner:not(.playoff__team--highlighted)::after {
  width: 0;
}

.playoff__container--final {
  margin: 0 0 100px 0 !important;
}

.playoff__container--final .playoff__item:before {
  content: '';
  position: absolute;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  top: -5px;
  left: -5px;
  border-radius: 14px;
  z-index: -1;
}

.playoff__container--gold .playoff__item:before {
}

.playoff__container--bronze .playoff__item:before {
}

.playoff__container--outsider .playoff__line-part {
  visibility: hidden;
}

.playoff__container--outsider .playoff__line-part:after {
  display: none;
}

.playoff__item-title {
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: -30px;
  left: 15px;
  color: var(--green);
}

.playoff-container {
  position: relative;
}

.playoff-container--full {
  width: calc(100% - 120px);
  margin: auto;
  padding: 60px 20px;
  overflow: auto;
}

@media (max-width: 1200px) {
  .playoff__column--last {
    display: none;
  }
}

@media (max-width: 780px) {
  .playoff__arrow {
    display: flex;
  }

  .playoff__step-line {
    height: 1px;
  }

  .playoff__step-item--32 .playoff__step-line:nth-child(even) {
    display: none;
  }

  .playoff__column--middle {
    display: none;
  }

  .playoff__grid--down .playoff__column--middle:nth-child(2) {
    display: block;
  }

  .playoff__grid--up .playoff__column--middle:last-child {
    display: block;
  }

  .playoff__column--main {
    margin: 0 auto;
  }

  .playoff__container--final {
    margin: 0 0 60px 0 !important;
  }

  .playoff__item-title {
    display: none;
  }

  .playoff__step-item {
    display: none;
  }

  .playoff__step-item--active {
    display: flex;
  }

  .playoff__step-container:first-child {
    background-color: var(--green);
    display: flex;
  }

  .playoff__step-container:not(:first-child) {
    display: none;
  }

  .playoff__body:nth-child(2) {
    margin: 20px 0 0 0;
  }
}

@media (max-width: 630px) {
  .playoff__step-item--32 .playoff__step-container:nth-child(even) {
  }

  .playoff__step-item--8 .playoff__step-container {
    justify-content: space-around;
  }

  .playoff__step-item--8 .playoff__step-line:nth-child(4) {
    margin-bottom: 10px;
  }

  .playoff__step-item:last-child {
    margin: 0;
  }

  .playoff__step-title {
    font-size: 10px;
  }

  .playoff__step-title-place {
    display: none;
  }

  .round-share {
    position: static;
    justify-content: flex-end;
    z-index: 1;
  }

  .playoff-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .playoff__step-title {
    font-size: 12px;
    line-height: 14px;
  }

  .playoff__body {
    margin: 30px 0 0 0;
  }
}
